import gql from 'graphql-tag'

export const CITIES_QUERY = gql`
  query GetCitiesQuery {
    getCities {
      cities {
        id
        title
      }
      total
    }
  }
`
