import { useMutation } from '@apollo/client'
import { Box, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { PageHeader } from '../../components/Layout'
import { UPDATE_PROFILE_MUTATION } from '../../graphql/queries/business'
import { IUpdateProfileData, IUpdateProfileInput, IUpdateProfileVariables } from '../../graphql/types/business'
import { useStores } from '../../stores/hooks'
import { IProfileFormValues, ProfileForm } from './components'

function Profile() {
  const navigate = useNavigate()
  const { authStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()
  const [updateProfile, { loading }] = useMutation<IUpdateProfileData, IUpdateProfileVariables>(UPDATE_PROFILE_MUTATION)

  const handleSubmit = async (values: IProfileFormValues) => {
    const input: IUpdateProfileInput = {
      name: values.name,
      phone: values.phone,
      password: values.password,
    }

    try {
      const { data } = await updateProfile({
        variables: { input },
      })

      if (data?.updateBusinessProfile) {
        authStore.updateBusiness(data.updateBusinessProfile)

        enqueueSnackbar('Профиль успешно обновлен!', { variant: 'success' })
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' })
    }
  }

  const handleCancel = () => {
    navigate('/')
  }

  return (
    <StyledContainer>
      <Box>
        <PageHeader title='Профиль' />
        <ProfileForm loading={loading} onSubmit={handleSubmit} onCancel={handleCancel} />
      </Box>
    </StyledContainer>
  )
}

const StyledContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export default Profile
