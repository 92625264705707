import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMutation } from '@apollo/client'

import { IPointFormValues, PointForm } from './PointForm'
import { useStores } from '../../../../stores/hooks'
import { CREATE_POINT_MUTATION, TARGET_POINTS_QUERY } from '../../../../graphql/queries/points'
import { ICreatePointData, ICreatePointVariables, IPointInput } from '../../../../graphql/types/points'
import { useSnackbar } from 'notistack'
import { usePointsFilter } from '../../../../graphql/hooks/targets'
import { usePagination } from '../../../../hooks/usePagination'

interface IProps {
  onCancel: () => void
}

export const AddPointModal: React.FC<IProps> = observer(({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { offset, limit } = usePagination()
  const { targetsStore } = useStores()
  const filter = usePointsFilter()
  const targetId = targetsStore.selectedTarget!.id
  const [createPoint, { loading }] = useMutation<ICreatePointData, ICreatePointVariables>(CREATE_POINT_MUTATION)

  const handleSubmit = async (values: IPointFormValues) => {
    const input: IPointInput = values

    await createPoint({
      variables: {
        targetId,
        input,
      },
      refetchQueries: [{ query: TARGET_POINTS_QUERY, variables: { targetId, filter, pagination: { offset, limit } } }],
    })

    enqueueSnackbar('Точка создана', {
      variant: 'success',
    })

    onCancel()
  }

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Новая точка</DialogTitle>
      <DialogContent>
        <PointForm loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
      </DialogContent>
    </Dialog>
  )
})
