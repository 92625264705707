import { useContext } from 'react'
import { ConfirmDialogContext, IConfirmDialogProps } from '../context/ConfirmDialogContext'

export default function useConfirmDialog() {
  const confirm = useContext(ConfirmDialogContext)

  return confirm === null
    ? (options: IConfirmDialogProps) => new Promise<void>((resolve, reject) => resolve())
    : confirm
}
