import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Typography, TextField, Box, Button } from '@mui/material'
import { Alert, AlertTitle } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { IDeleteTargetData, IDeleteTargetVariables, ITarget } from '../../../../graphql/types/targets'
import { LoadableButton } from '../../../../components/Form'
import { DELETE_TARGET_MUTATION } from '../../../../graphql/queries/targets'
import { useStores } from '../../../../stores/hooks'

interface IProps {
  target: ITarget
  onCancel: () => void
}

export const ConfirmDeleteModal: React.FC<IProps> = ({ target, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [confirmTitle, setConfirmTitle] = useState<string>('')
  const [confirmError, setConfirmError] = useState<boolean>(false)
  const [deleteTarget, { loading }] = useMutation<IDeleteTargetData, IDeleteTargetVariables>(DELETE_TARGET_MUTATION)
  const { targetsStore } = useStores()

  const handleChangeConfirmTitle = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmTitle(target.value)
  }

  const handleSubmit = async () => {
    if (confirmTitle !== target.title) {
      setConfirmError(true)
      return
    }

    setConfirmError(false)

    try {
      const { data } = await deleteTarget({
        variables: { id: target.id },
      })

      if (data?.deleteTarget) {
        enqueueSnackbar('Объект успешно удален', { variant: 'success' })

        targetsStore.reloadTargets()
        navigate('/')
      } else {
        enqueueSnackbar('Объект не удален', { variant: 'error' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Удаление объекта</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>
            <Typography component='span'>Вы собираетесь удалить объект </Typography>
            <Typography component='span' variant='h6'>
              {target.title}
            </Typography>
          </Typography>
        </Box>
        <Box mb={3}>
          <Alert severity='error'>
            <AlertTitle>Вы действительно хотите удалить?</AlertTitle>
            Будут удалены все точки, пользователи и статистика. После удаления данные нельзя восстановить.
          </Alert>
        </Box>
        <Box mb={4}>
          <Typography gutterBottom>Введите название объекта для подтверждения удаления</Typography>
          <TextField
            label='Название объекта'
            value={confirmTitle}
            onChange={handleChangeConfirmTitle}
            error={confirmError}
            margin='none'
            required
          />
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Box mr={2}>
            <Button onClick={onCancel}>Закрыть</Button>
          </Box>
          <LoadableButton loading={loading} color='secondary' variant='contained' onClick={handleSubmit}>
            Удалить
          </LoadableButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
