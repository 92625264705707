import React from 'react'
import { Box, Paper, Typography } from '@mui/material'

interface IProps {
  message?: string
}

export const EmptyListMessage: React.FC<IProps> = ({ message = 'Нет записей' }) => {
  return (
    <Paper>
      <Box p={3} textAlign='center'>
        <Typography color='textSecondary'>{message}</Typography>
      </Box>
    </Paper>
  )
}
