export const PHONE_REGEXP =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
export const PHONE_MASK_DEFAULT = '+7 (999) 999-99-99'

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const DATE_FORMAT = 'DD-MM-YYYY'

export enum UserInquiryStatus {
  PENDING = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

export const ROWS_PER_PAGE = [15, 30, 50]
