import { useQuery } from '@apollo/client'

import { TARGET_POINTS_QUERY } from '../../queries/points'
import { IPaginationInput } from '../../types/common'
import { ITargetPointsData, ITargetPointsFilter, ITargetPointsVariables } from '../../types/points'

export const useTargetPoints = (targetId: number, filter?: ITargetPointsFilter, pagination?: IPaginationInput) => {
  const { data, ...query } = useQuery<ITargetPointsData, ITargetPointsVariables>(TARGET_POINTS_QUERY, {
    variables: { targetId, filter, pagination },
  })

  const points = data?.targetPoints.points || []
  const total = data?.targetPoints.total || 0

  return { points, total, ...query }
}
