import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  debounce,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useCallback, useEffect, useState } from 'react'
import { useDeletePoints, useSuspendPoints } from '../../../../graphql/hooks/targets'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import { useSnackbar } from 'notistack'
import { MultiplePointsSelect } from './MultiplePointsSelect'
import { ITargetUsersFilterInput } from '../../../../graphql/types/targets'

interface IProps {
  toggleAllSelect: VoidFunction
  isAllSelected: boolean
  total: number
  selectedUsers: number[]
  clearSelectedUsers: VoidFunction
  filter: ITargetUsersFilterInput
  onChangeFilter: (filter: ITargetUsersFilterInput) => void
}

export const UsersToolbar: React.FC<IProps> = ({
  toggleAllSelect,
  isAllSelected,
  total,
  selectedUsers,
  clearSelectedUsers,
  filter,
  onChangeFilter,
}) => {
  const { id } = useParams()
  const [searchText, setSearchText] = useState<string>(filter.search ?? '')

  const confirmDialog = useConfirmDialog()
  const { enqueueSnackbar } = useSnackbar()

  const [deletePoints] = useDeletePoints(parseInt(id || '0', 10))
  const [suspendPoints] = useSuspendPoints(parseInt(id || '0', 10))

  const updateSearchQuery = () => {
    onChangeFilter({ search: searchText })
  }

  const delayedUpdateSearch = useCallback(debounce(updateSearchQuery, 500), [searchText])

  useEffect(() => {
    delayedUpdateSearch()

    return delayedUpdateSearch.clear
  }, [delayedUpdateSearch, searchText])

  const handleChangePoints = (pointIds: number[]) => {
    onChangeFilter({ pointIds })
  }

  const handleDeletePoints = async () => {
    try {
      await confirmDialog({ message: 'Вы действительно хотите удалить точку?' })
    } catch {
      return
    }

    try {
      const { data } = await deletePoints({ variables: { ids: selectedUsers } })

      if (data) {
        clearSelectedUsers()
      }
    } catch (error) {
      console.error(error)
    }

    enqueueSnackbar('Точка удалена', {
      variant: 'success',
    })
  }

  const handleSuspendPoints = async () => {
    try {
      await confirmDialog({ message: 'Вы действительно хотите приостановить точку?' })
    } catch {
      return
    }

    try {
      const { data } = await suspendPoints({ variables: { ids: selectedUsers } })

      if (data) {
        clearSelectedUsers()
      }
    } catch (error) {
      console.error(error)
    }

    enqueueSnackbar('Точка приостановлена', {
      variant: 'success',
    })
  }

  if (selectedUsers.length === 0) {
    return (
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Checkbox
          checked={!!total && isAllSelected}
          onChange={toggleAllSelect}
          indeterminate={selectedUsers.length !== 0 && !isAllSelected}
          disabled={!total}
        />
        <Typography variant='subtitle1' fontWeight={700} flex={1}>
          Всего: {total}
        </Typography>
        <TextField
          sx={{ width: 190, m: 0, backgroundColor: 'white' }}
          label='Поиск...'
          name='searchText'
          size='small'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: !!searchText.length && (
              <InputAdornment position='end'>
                <IconButton size='small' edge='end' onClick={() => setSearchText('')}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <MultiplePointsSelect value={filter.pointIds ?? []} onChange={handleChangePoints} />
      </Stack>
    )
  }

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Checkbox
        checked={isAllSelected}
        onChange={toggleAllSelect}
        indeterminate={selectedUsers.length !== 0 && !isAllSelected}
      />
      <Typography variant='subtitle1' fontWeight={700} flex={1}>
        Выбрано пользователей: {selectedUsers.length}
      </Typography>
      <Box>
        <Button variant='outlined' color='primary' size='small' sx={{ mr: 2 }} onClick={handleSuspendPoints}>
          Разрешения
        </Button>
        <Button variant='outlined' color='error' size='small' onClick={handleDeletePoints}>
          Удалить
        </Button>
      </Box>
    </Stack>
  )
}
