import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Alert } from '@mui/material'
import { CheckCircle as OkIcon } from '@mui/icons-material'
import { useMutation } from '@apollo/client'

import { IResetPasswordFormValues, ResetPasswordForm } from './ResetPasswordForm'
import { SEND_RESET_PASSWORD_EMAIL } from '../../../graphql/queries/business'
import { ISendResetPasswordEmailData, ISendResetPasswordEmailVariables } from '../../../graphql/types/business'

export const SendResetEmail: React.FC = () => {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [sendEmail, { loading }] = useMutation<ISendResetPasswordEmailData, ISendResetPasswordEmailVariables>(
    SEND_RESET_PASSWORD_EMAIL
  )

  const handleSubmit = async (values: IResetPasswordFormValues) => {
    try {
      const { data } = await sendEmail({
        variables: { email: values.email },
      })

      if (data?.sendResetPasswordEmailToBusiness) {
        setEmailSent(true)
      } else {
        setError('Неверный email')
      }
    } catch (e) {
      console.log((e as Error).message)
    }
  }

  return (
    <div>
      {!!error && (
        <Box my={2}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      )}
      {emailSent ? (
        <Box p={2} color='success.main' textAlign='center'>
          <OkIcon color='inherit' fontSize='large' />
          <Typography>Письмо отправлено успешно</Typography>
        </Box>
      ) : (
        <>
          <Box mt={2}>
            <Typography variant='caption' color='textSecondary'>
              На указанный email будет отправлено письмо с дальнейшими инструкциями по восстановлению пароля.
            </Typography>
          </Box>
          <ResetPasswordForm loading={loading} onSubmit={handleSubmit} />
        </>
      )}
    </div>
  )
}
