import { useLazyQuery } from '@apollo/client'
import { TARGET_USER_WITH_TOTAL } from '../../queries/targets'

import { ITargetUsersWithTotalData, ITargetUserWithTotalVariables } from '../../types/targets'

export const useFindTargetUsers = (targetId: number) => {
  return useLazyQuery<ITargetUsersWithTotalData, Partial<ITargetUserWithTotalVariables>>(TARGET_USER_WITH_TOTAL, {
    variables: { targetId, pagination: { offset: 0, limit: 10 } },
  })
}
