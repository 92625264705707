import { Box, List, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PageHeader } from '../../../components/Layout'
import { EmptyListMessage, Loader } from '../../../components/UI'
import { IUserInquiry } from '../../../graphql/types/inquries'
import { useTargetInquiries } from '../../../graphql/hooks/targets'
import { ConfirmInquiryModal, InquiryItem } from './components'

const Inquiries: React.FC = () => {
  const { id } = useParams()
  const classes = useStyles()
  const [selectedInquiry, setSelectedInquiry] = useState<IUserInquiry | null>()
  const { inquiries, loading } = useTargetInquiries(parseInt(id || '0', 10))

  const closeModal = () => {
    setSelectedInquiry(null)
  }

  const handleClickInquiry = (inquiry: IUserInquiry) => () => {
    setSelectedInquiry(inquiry)
  }

  return (
    <>
      <Box mt={3}>
        <PageHeader title='Заявки' />
        {loading && <Loader />}
        {!loading && inquiries.length > 0 ? (
          <Paper className={classes.paper} elevation={0}>
            <List component='div'>
              {inquiries.map((inquiry) => (
                <InquiryItem key={`inq-${inquiry.userId}`} inquiry={inquiry} onClick={handleClickInquiry(inquiry)} />
              ))}
            </List>
          </Paper>
        ) : (
          <EmptyListMessage message='Заявок нет' />
        )}
      </Box>
      {!!selectedInquiry && <ConfirmInquiryModal inquiry={selectedInquiry} onCancel={closeModal} />}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(3),
  },
}))

export default Inquiries
