import { useSearchParams } from 'react-router-dom'

export const usePagination = (l?: number) => {
  const [query, setQuery] = useSearchParams()
  const page = parseInt(query.get('page') || '1')
  const limit = l ?? 10
  const offset = (page - 1) * limit

  const setPage = (value: number) => {
    query.set('page', value.toString())
    setQuery(query)
  }

  return {
    page,
    offset,
    limit,
    setPage,
  }
}
