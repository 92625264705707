import { useQuery } from '@apollo/client'
import { useEffect } from 'react'

import { TARGET_INQUIRIES_QUERY } from '../../queries/inquiries'
import { ITargetInquiriesData, ITargetInquiriesVariables } from '../../types/inquries'

export const useTargetInquiries = (targetId: number) => {
  const { data, startPolling, stopPolling, ...query } = useQuery<ITargetInquiriesData, ITargetInquiriesVariables>(
    TARGET_INQUIRIES_QUERY,
    {
      variables: { targetId },
    }
  )

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      startPolling(10000)
    }

    return () => {
      stopPolling()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inquiries = data?.getUserInquiriesByTarget || []

  return { inquiries, ...query }
}
