import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Alert, Box, Dialog, DialogContent, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { CREATE_TARGET_MUTATION, TARGETS_QUERY } from '../../../graphql/queries/targets'
import { ICreateTargetData, ICreateTargetVariables, ITargetInput } from '../../../graphql/types/targets'
import { ITargetFormValues, TargetForm } from '../../../components/Target'
import { useStores } from '../../../stores/hooks'

interface IProps {
  open: boolean
  onClose: VoidFunction
}

export const CreateTargetDialog: React.FC<IProps> = ({ open, onClose }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { targetsStore } = useStores()

  const [createTarget, { loading, error }] = useMutation<ICreateTargetData, ICreateTargetVariables>(
    CREATE_TARGET_MUTATION
  )

  const handleSubmit = async (values: ITargetFormValues) => {
    try {
      const input: ITargetInput = {
        title: values.title,
        cityId: parseInt(values.cityId, 10),
        address: values.address,
      }

      const { data } = await createTarget({
        variables: { input },
        refetchQueries: [{ query: TARGETS_QUERY }],
      })

      const target = data?.createTarget

      if (target) {
        targetsStore.addTarget(target)
        navigate(`/target/${target.id}/points`)
      }
    } catch (e) {
      console.log(e)
    }

    enqueueSnackbar('Объект создан', {
      variant: 'success',
    })

    onClose()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onClose}>
      <DialogContent>
        <Typography variant='h6' fontWeight={700} mb={1}>
          Новый объект
        </Typography>
        {!!error && (
          <Box my={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <TargetForm loading={loading} onSubmit={handleSubmit} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}
