import { Badge, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, styled } from '@mui/material'
import {
  Room as PointIcon,
  BarChart as StatisticsIcon,
  Settings as SettingsIcon,
  Group as UsersIcon,
  PersonAdd as AddUserIcon,
  SvgIconComponent,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTargetInquiries } from '../../graphql/hooks/targets'

interface IMenuItem {
  name: string
  label: string
  path: string
  icon: SvgIconComponent
}

interface IProps {
  targetId: number
}

const MENU_ITEMS: IMenuItem[] = [
  { name: 'points', label: 'Точки', path: '/target/:id/points', icon: PointIcon },
  { name: 'inquiries', label: 'Заявки', path: '/target/:id/inquiries', icon: AddUserIcon },
  { name: 'users', label: 'Пользователи', path: '/target/:id/users', icon: UsersIcon },
  { name: 'statistics', label: 'Статистика', path: '/target/:id/statistics', icon: StatisticsIcon },
  { name: 'settings', label: 'Настройки', path: '/target/:id/settings', icon: SettingsIcon },
]

export const TargetMenu: React.FC<IProps> = ({ targetId }) => {
  const { pathname } = useLocation()
  const { inquiries } = useTargetInquiries(targetId)
  const navigate = useNavigate()

  const handleNavigate = (to: string) => {
    navigate(to)
  }

  return (
    <List component='nav'>
      {MENU_ITEMS.map((item) => (
        <ListItemButtonStyled
          key={item.path}
          selected={pathname === item.path.replace(':id', targetId.toString())}
          onClick={() => handleNavigate(item.path.replace(':id', targetId.toString()))}
        >
          <ListItemIcon>
            <item.icon />
          </ListItemIcon>
          <ListItemText primary={item.label} />
          {item.name === 'inquiries' && inquiries.length > 0 && (
            <ListItemSecondaryAction>
              <Badge badgeContent={inquiries.length} color='secondary'></Badge>
            </ListItemSecondaryAction>
          )}
        </ListItemButtonStyled>
      ))}
    </List>
  )
}

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0, 2),
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  '.MuiListItemIcon-root': {
    color: 'white',
    minWidth: '2.5rem',
  },
  '&:hover': {
    backgroundColor: 'rgba(255,255,255, 15%)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255,255,255, 15%)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(255,255,255, 15%)',
  },
}))
