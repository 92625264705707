import React from 'react'
import { TextField, Box, Paper, Button, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../Form'
import { createSchema } from './validation'
import { CitySelectField } from './CitySelectField'
import { ITarget } from '../../graphql/types/targets'

export interface ITargetFormValues {
  title: string
  cityId: string
  address: string
}

interface IProps {
  target?: ITarget
  onSubmit: (values: ITargetFormValues) => void
  loading?: boolean
  onClose?: VoidFunction
}

export const TargetForm: React.FC<IProps> = ({ target, onSubmit, loading, onClose }) => {
  const classes = useStyles()
  const initialValues: ITargetFormValues = {
    title: target?.title || '',
    cityId: target?.address.city.id.toString() || '',
    address: target?.address.address || '',
  }

  const handleFormSubmit = (values: ITargetFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, errors, setFieldValue, handleChange, handleSubmit }: FormikProps<ITargetFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Paper>
            <Box mb={3}>
              <TextField
                label='Название'
                name='title'
                value={values.title}
                onChange={handleChange}
                error={!!errors.title}
                required
              />
              <CitySelectField value={values.cityId} onChange={(e) => setFieldValue('cityId', e.target.value)} />
              <TextField
                label='Адрес объекта'
                name='address'
                value={values.address}
                onChange={handleChange}
                error={!!errors.address}
                required
              />
            </Box>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              {!!onClose && (
                <Button variant='text' onClick={onClose}>
                  Закрыть
                </Button>
              )}
              <LoadableButton loading={loading} color='primary' variant='contained' type='submit'>
                Сохранить
              </LoadableButton>
            </Stack>
          </Paper>
        </form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}))
