import gql from 'graphql-tag'

export const POINT_FRAGMENT = gql`
  fragment PointFragment on Point {
    id
    title
    deviceCode
    isActivated
    isSuspended
    targetId
    createdAt
  }
`

export const TARGET_POINTS_QUERY = gql`
  query GetTargetPointsQuery($targetId: Int!, $filter: TargetPointsFilter, $pagination: PaginationInput) {
    targetPoints(targetId: $targetId, filter: $filter, pagination: $pagination) {
      points {
        ...PointFragment
      }
      total
    }
  }
  ${POINT_FRAGMENT}
`

export const CREATE_POINT_MUTATION = gql`
  mutation CreatePointMutation($targetId: Int!, $input: PointInput!) {
    createPoint(targetId: $targetId, input: $input) {
      ...PointFragment
    }
  }
  ${POINT_FRAGMENT}
`

export const UPDATE_POINT_MUTATION = gql`
  mutation UpdatePointMutation($id: Int!, $input: PointInput!) {
    updatePoint(id: $id, input: $input) {
      ...PointFragment
    }
  }
  ${POINT_FRAGMENT}
`

export const SWITCH_POINT_SUSPENSION_MUTATION = gql`
  mutation SwitchPointSuspensionMutation($id: Int!, $suspended: Boolean!) {
    switchPointSuspension(id: $id, suspended: $suspended)
  }
`

export const POINTS_STATUSES_QUERY = gql`
  query GetPointsStatusesQuery($targetId: Int!) {
    getPointsStatusByTarget(targetId: $targetId) {
      deviceCode
      isOnline
    }
  }
`

export const POINTS_STATUSES_SUBSCRIPTION = gql`
  subscription PointsStatusesSubscription($targetId: Int!) {
    pointsStatusesByTarget(targetId: $targetId) {
      deviceCode
      isOnline
    }
  }
`
export const DELETE_POINTS_MUTATION = gql`
  mutation DeletePoints($ids: [Int!]!) {
    deletePoints(ids: $ids)
  }
`

export const SUSPEND_POINTS_MUTATION = gql`
  mutation SuspendPoints($ids: [Int!]!) {
    suspendPoints(ids: $ids)
  }
`
