import gql from 'graphql-tag'

export const BUSINESS_FRAGMENT = gql`
  fragment BusinessFragment on Business {
    id
    email
    name
    phone
    createdAt
  }
`

export const REGISTER_BUSINESS_MUTATION = gql`
  mutation RegisterBusinessMutation($input: RegisterBusinessInput!) {
    registerBusiness(input: $input) {
      business {
        ...BusinessFragment
      }
      token
    }
  }
  ${BUSINESS_FRAGMENT}
`

export const LOGIN_BUSINESS_MUTATION = gql`
  mutation LoginBusinessMutation($email: String!, $password: String!) {
    loginBusiness(email: $email, password: $password) {
      business {
        ...BusinessFragment
      }
      token
    }
  }
  ${BUSINESS_FRAGMENT}
`

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateBusinessProfileMutation($input: UpdateBusinessProfileInput!) {
    updateBusinessProfile(input: $input) {
      ...BusinessFragment
    }
  }
  ${BUSINESS_FRAGMENT}
`

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation SendResetPasswordEmailMutation($email: String!) {
    sendResetPasswordEmailToBusiness(email: $email)
  }
`

export const VERIFY_RESET_PASSWORD_TOKEN = gql`
  mutation VerifyResetPasswordTokenMutation($token: String!) {
    verifyBusinessResetPasswordToken(token: $token)
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($token: String!, $newPassword: String!) {
    resetPasswordByBusiness(token: $token, newPassword: $newPassword)
  }
`
