import { useMutation } from '@apollo/client'
import { Box, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Alert } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { REGISTER_BUSINESS_MUTATION } from '../../graphql/queries/business'
import { IRegisterBusinessData, IRegisterBusinessInput, IRegisterBusinessVariables } from '../../graphql/types/business'
import { useStores } from '../../stores/hooks'
import { IRegisterFormValues, RegisterForm } from './components'

function Register() {
  const classes = useStyles()
  const { authStore } = useStores()
  const navigate = useNavigate()
  const [registerBusiness, { loading, error }] = useMutation<IRegisterBusinessData, IRegisterBusinessVariables>(
    REGISTER_BUSINESS_MUTATION
  )

  const handleSubmit = async (values: IRegisterFormValues) => {
    try {
      const input: IRegisterBusinessInput = { ...values }

      const { data } = await registerBusiness({
        variables: { input },
      })

      const business = data?.registerBusiness.business
      const token = data?.registerBusiness.token

      if (business && token) {
        authStore.login(business, token)
        navigate('/')
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant='h5'>Регистрация</Typography>
        {!!error && (
          <Box my={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <RegisterForm loading={loading} onSubmit={handleSubmit} />
      </Paper>

      <Box mt={3} textAlign='center'>
        <Typography>
          Уже есть аккаунт? <Link to='/'>Войти</Link>
        </Typography>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}))

export default Register
