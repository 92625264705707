import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      light: '#81A0F2',
      main: '#2F55D4',
      dark: '#2240B6',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#ff6e40',
    },
    success: {
      light: '#A7FAB8',
      main: '#23ce7b',
      dark: '#0B7763',
    },
    background: {
      default: '#f7f7ff',
    },
  },
  typography: {
    fontFamily: 'Nunito, sans-serif',
    button: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 16,
    },
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 6,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 48,
        },

        sizeSmall: {
          height: 36,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #f1f1f1',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: grey[50],
          '& fieldset': {
            borderColor: grey[300],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
  },
})
