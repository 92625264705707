import React from 'react'
import { Navigate } from 'react-router-dom'
import { useStores } from '../../stores/hooks'

const Logout: React.FC = () => {
  const { authStore } = useStores()

  React.useEffect(() => {
    authStore.logout()
  }, [authStore])

  return <Navigate to='/login' replace />
}

export default Logout
