import gql from 'graphql-tag'
import { USER_FRAGMENT } from './users'

export const TARGET_FRAGMENT = gql`
  fragment TargetFragment on Target {
    id
    title
    address {
      id
      latitude
      longitude
      city {
        id
        title
      }
      address
      createdAt
    }
    registrationCode
    isRegistrationEnabled
    createdAt
  }
`

export const TARGETS_QUERY = gql`
  query GetBusinessTargetsQuery {
    businessTargets {
      targets {
        ...TargetFragment
      }
      total
    }
  }
  ${TARGET_FRAGMENT}
`

export const TARGET_QUERY = gql`
  query GetBusinessTargetQuery($id: Int!) {
    businessTarget(id: $id) {
      ...TargetFragment
    }
  }
  ${TARGET_FRAGMENT}
`

export const CREATE_TARGET_MUTATION = gql`
  mutation CreateTargetMutation($input: TargetInput!) {
    createTarget(input: $input) {
      ...TargetFragment
    }
  }
  ${TARGET_FRAGMENT}
`

export const UPDATE_TARGET_MUTATION = gql`
  mutation UpdateTargetMutation($id: Int!, $input: TargetInput!) {
    updateTarget(id: $id, input: $input) {
      ...TargetFragment
    }
  }
  ${TARGET_FRAGMENT}
`

export const DELETE_TARGET_MUTATION = gql`
  mutation DeleteTargetMutation($id: Int!) {
    deleteTarget(id: $id)
  }
`

export const SWITCH_TARGET_REGISTRATION = gql`
  mutation SwitchTargetRegistrationMutation($id: Int!) {
    switchTargetRegistration(id: $id)
  }
`

export const TARGET_USER_WITH_TOTAL = gql`
  query TargetUsersWithTotal($targetId: Int!, $filter: TargetUsersFilterInput, $pagination: PaginationInput) {
    targetUsersWithTotal(targetId: $targetId, filter: $filter, pagination: $pagination) {
      users {
        ...UserFragment
        pointsByTarget(targetId: $targetId) {
          point {
            id
            title
            isSuspended
          }
          name
          isActive
          createdAt
        }
      }
      total
    }
  }
  ${USER_FRAGMENT}
`
