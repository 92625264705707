import { makeObservable, observable, action, computed } from 'mobx'

import { IBusiness } from '../graphql/types/business'

export class AuthStore {
  business: IBusiness | null = null
  token: string | null = null

  constructor() {
    makeObservable(this, {
      business: observable,
      token: observable,
      isLoggedIn: computed,
      login: action,
      logout: action,
      updateBusiness: action,
      loadFromStorage: action,
    })

    this.loadFromStorage()
  }

  get isLoggedIn() {
    return !!this.token && !!this.business
  }

  getToken() {
    return this.token
  }

  login(business: IBusiness, token: string) {
    this.business = business
    this.token = token

    this.saveToStorage()
  }

  logout() {
    this.business = null
    this.token = null

    this.clearStorage()
  }

  updateBusiness(business: IBusiness) {
    this.business = business

    this.saveToStorage()
  }

  private saveToStorage() {
    window.localStorage.setItem('@auth', JSON.stringify({ ...this.business, token: this.token }))
  }

  private clearStorage() {
    window.localStorage.removeItem('@auth')
  }

  loadFromStorage() {
    const auth = window.localStorage.getItem('@auth')

    if (auth) {
      const { token, ...business } = JSON.parse(auth)

      this.business = business
      this.token = token
    }
  }
}

export default new AuthStore()
