import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Card, Divider, List, Pagination } from '@mui/material'
import { AddCircle as AddIcon } from '@mui/icons-material'

import { Loader } from '../../../components/UI'
import { usePointsFilter, useTargetPoints } from '../../../graphql/hooks/targets'
import { AddPointModal, PointItem, PointsToolbar } from './components'
import { PageHeader } from '../../../components/Layout'
import { usePagination } from '../../../hooks/usePagination'

const Points: React.FC = () => {
  const { id } = useParams()
  const filter = usePointsFilter()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [selectedPoints, setSelectedPoints] = useState<number[]>([])
  const { page, limit, offset, setPage } = usePagination()

  const { points, loading, total } = useTargetPoints(parseInt(id || '0', 10), filter, { offset, limit })

  const toggleModal = () => {
    setModalVisible((prev) => !prev)
  }

  const toggleSelect = (id: number) => {
    setSelectedPoints((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id)
      }
      return [id, ...prev]
    })
  }

  const toggleSelectAll = () => {
    if (selectedPoints.length !== points.length) {
      setSelectedPoints(points.map((item) => item.id))
    } else {
      setSelectedPoints([])
    }
  }

  const clearSelectedPoints = () => {
    setSelectedPoints([])
  }

  return (
    <>
      <Box mt={3}>
        <PageHeader
          title='Точки'
          action={
            <Button size='small' color='primary' variant='contained' startIcon={<AddIcon />} onClick={toggleModal}>
              Добавить точку
            </Button>
          }
        />

        {loading && <Loader />}
        {!loading && points.length > 0 && (
          <Card>
            <Box p={2}>
              <PointsToolbar
                toggleAllSelect={toggleSelectAll}
                isAllSelected={selectedPoints.length === points.length}
                total={total}
                selectedPoints={selectedPoints}
                clearSelectedPoints={clearSelectedPoints}
              />
            </Box>
            <Divider />
            <List component='div' sx={{ p: 0 }}>
              {points.map((point) => (
                <Box key={`point-${point.id}`}>
                  <PointItem point={point} selected={selectedPoints.includes(point.id)} toggleSelect={toggleSelect} />
                  <Divider />
                </Box>
              ))}
            </List>
            {total > 0 && Math.ceil(total / limit) > 1 && (
              <Box display='flex' justifyContent='center' p={2}>
                <Pagination count={Math.ceil(total / limit)} page={page} onChange={(e, value) => setPage(value)} />
              </Box>
            )}
          </Card>
        )}
      </Box>

      {modalVisible && <AddPointModal onCancel={toggleModal} />}
    </>
  )
}

export default Points
