import { useQuery } from '@apollo/client'
import { TARGET_USER_WITH_TOTAL } from '../../queries/targets'

import { IPaginationInput } from '../../types/common'
import { ITargetUsersFilterInput, ITargetUsersWithTotalData, ITargetUserWithTotalVariables } from '../../types/targets'

export const useTargetUsersWithTotal = (
  targetId: number,
  filter?: ITargetUsersFilterInput,
  pagination?: IPaginationInput
) => {
  const { data, ...query } = useQuery<ITargetUsersWithTotalData, ITargetUserWithTotalVariables>(
    TARGET_USER_WITH_TOTAL,
    {
      variables: { targetId, filter, pagination },
      fetchPolicy: 'network-only',
    }
  )

  const users = data?.targetUsersWithTotal.users || []
  const total = data?.targetUsersWithTotal.total || 0

  return { users, ...query, total }
}
