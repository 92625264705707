import { useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  debounce,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useCallback, useEffect, useState } from 'react'
import { useDeletePoints, useSuspendPoints } from '../../../../graphql/hooks/targets'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import { useSnackbar } from 'notistack'

interface IProps {
  toggleAllSelect: VoidFunction
  isAllSelected: boolean
  total: number
  selectedPoints: number[]
  clearSelectedPoints: VoidFunction
}

export const PointsToolbar: React.FC<IProps> = ({
  toggleAllSelect,
  isAllSelected,
  total,
  selectedPoints,
  clearSelectedPoints,
}) => {
  const { id } = useParams()
  const [query, setQuery] = useSearchParams()
  const [searchText, setSearchText] = useState<string>(query.get('q') ?? '')

  const confirmDialog = useConfirmDialog()
  const { enqueueSnackbar } = useSnackbar()

  const [deletePoints] = useDeletePoints(parseInt(id || '0', 10))
  const [suspendPoints] = useSuspendPoints(parseInt(id || '0', 10))

  const updateSearchQuery = () => {
    if (searchText === '') {
      query.delete('q')
    } else {
      query.set('q', searchText)
    }

    setQuery(query)
  }

  const delayedUpdateSearch = useCallback(debounce(updateSearchQuery, 500), [searchText])

  useEffect(() => {
    delayedUpdateSearch()

    return delayedUpdateSearch.clear
  }, [delayedUpdateSearch, searchText])

  const handleDeletePoints = async () => {
    try {
      await confirmDialog({ message: 'Вы действительно хотите удалить точку?' })
    } catch {
      return
    }

    try {
      const { data } = await deletePoints({ variables: { ids: selectedPoints } })

      if (data) {
        clearSelectedPoints()
      }
    } catch (error) {
      console.error(error)
    }

    enqueueSnackbar('Точка удалена', {
      variant: 'success',
    })
  }

  const handleSuspendPoints = async () => {
    try {
      await confirmDialog({ message: 'Вы действительно хотите приостановить точку?' })
    } catch {
      return
    }

    try {
      const { data } = await suspendPoints({ variables: { ids: selectedPoints } })

      if (data) {
        clearSelectedPoints()
      }
    } catch (error) {
      console.error(error)
    }

    enqueueSnackbar('Точка приостановлена', {
      variant: 'success',
    })
  }

  if (selectedPoints.length === 0) {
    return (
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Checkbox
          checked={isAllSelected}
          onChange={toggleAllSelect}
          indeterminate={selectedPoints.length !== 0 && !isAllSelected}
        />
        <Typography variant='subtitle1' fontWeight={700} flex={1}>
          Всего: {total}
        </Typography>
        <TextField
          sx={{ width: 190, margin: 0, backgroundColor: 'white' }}
          label='Поиск...'
          name='searchText'
          size='small'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: !!searchText.length && (
              <InputAdornment position='end'>
                <IconButton size='small' edge='end' onClick={() => setSearchText('')}>
                  <ClearIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    )
  }

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Checkbox
        checked={isAllSelected}
        onChange={toggleAllSelect}
        indeterminate={selectedPoints.length !== 0 && !isAllSelected}
      />
      <Typography variant='subtitle1' fontWeight={700} flex={1}>
        Выбрано точек: {selectedPoints.length}
      </Typography>
      <Box>
        <Button variant='outlined' color='primary' size='small' sx={{ mr: 2 }} onClick={handleSuspendPoints}>
          Приостановить
        </Button>
        <Button variant='outlined' color='error' size='small' onClick={handleDeletePoints}>
          Удалить
        </Button>
      </Box>
    </Stack>
  )
}
