import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { IAccessLogFilter } from '../../../../graphql/types/access-logs'
import { StatisticsFilter } from './StatisticsFilter'

const filterFromQS = (search: string): IAccessLogFilter => {
  const query = new URLSearchParams(search)

  return {
    userId: query.get('userId') === null ? undefined : Number(query.get('userId')),
    pointId: query.get('pointId') === null ? undefined : Number(query.get('pointId')),
    period: {
      startDate: query.get('startDate') || undefined,
      endDate: query.get('endDate') || undefined,
    },
  }
}

const filterToQSArray = (filter: IAccessLogFilter): string[] => {
  const arr: string[] = []
  Object.keys(filter).forEach((key) => {
    const value = filter[key as keyof IAccessLogFilter]

    if (key === 'period' && typeof value === 'object') {
      if (value?.startDate) {
        arr.push(`startDate=${value.startDate}`)
      }
      if (value?.endDate) {
        arr.push(`endDate=${value.endDate}`)
      }
    } else {
      if (value !== undefined) {
        arr.push(`${key}=${value}`)
      }
    }
  })

  return arr
}

export const useStatisticsFilter = (targetId: number) => {
  const path = `/target/${targetId}/statistics`
  const navigate = useNavigate()
  const searchString = useLocation().search
  const filter = filterFromQS(searchString)
  const hasFilter = () => filterToQSArray(filter).length > 0
  const [filterVisible, setFilterVisible] = useState<boolean>(hasFilter)

  const toggleFilter = () => {
    setFilterVisible(!filterVisible)

    if (filterVisible === true) {
      navigate(path)
    }
  }

  const handleChangeFilter = (filter: IAccessLogFilter) => {
    const arr = filterToQSArray(filter)

    navigate(`${path}${arr.length > 0 ? '?' + arr.join('&') : ''}`)
  }

  const renderFilter = () =>
    filterVisible ? <StatisticsFilter targetId={targetId} filter={filter} onChange={handleChangeFilter} /> : null

  return {
    filter,
    filterVisible,
    toggleFilter,
    renderFilter,
  }
}
