import React from 'react'
import { CircularProgress, Button, ButtonProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

interface IProps extends ButtonProps {
  loading?: boolean
}

export const LoadableButton: React.FC<IProps> = ({ loading, children, ...props }) => {
  const classes = useStyles()

  return (
    <Button {...props} disabled={loading}>
      {loading && <CircularProgress size={20} className={classes.loader} />} {children}
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  loader: {
    marginRight: theme.spacing(2),
  },
}))
