import React from 'react'
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material'
import { FilterList as FilterIcon, Close as CloseIcon } from '@mui/icons-material'

import { PageHeader } from '../../../components/Layout'
import { useAccessLogs } from '../../../graphql/hooks/accessLogs'
import { EmptyListMessage, Loader } from '../../../components/UI'
import { DataTable, Pagination } from '../../../components/Data'
import { ROWS_PER_PAGE } from '../../../core/constants'
import { columns } from './columns'
import { useStatisticsFilter } from './components'
import { useParams } from 'react-router-dom'

const Statistics: React.FC = () => {
  const { id } = useParams()
  const targetId = parseInt(id || '0', 10)
  const { filter, filterVisible, toggleFilter, renderFilter } = useStatisticsFilter(targetId)
  const { accessLogs, total, loading, page, pagination, handleChangePage, handleChangePerPage } = useAccessLogs(
    targetId,
    filter
  )

  return (
    <>
      <PageHeader title='Статистика' />
      <Paper>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>Всего: {total}</Typography>
          <IconButton onClick={toggleFilter} size='large'>
            {filterVisible ? <CloseIcon /> : <FilterIcon />}
          </IconButton>
        </Box>
        {renderFilter()}
        <Divider />
        {loading ? (
          <Loader />
        ) : (
          <>
            {accessLogs.length > 0 ? (
              <>
                <DataTable data={accessLogs} columns={columns} />
                <Box mt={3}>
                  <Pagination
                    rowsPerPage={pagination.limit}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    onChangeRowsPerPage={handleChangePerPage}
                    onChangePage={handleChangePage}
                    page={page}
                    count={total}
                  />
                </Box>
              </>
            ) : (
              <EmptyListMessage />
            )}
          </>
        )}
      </Paper>
    </>
  )
}

export default Statistics
