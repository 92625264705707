import React from 'react'
import { TextField, Box, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../../components/Form'
import { createSchema } from './validation'
import { IPoint } from '../../../../graphql/types/points'

export interface IPointFormValues {
  title: string
  deviceCode: string
}

interface IProps {
  point?: IPoint
  onSubmit: (values: IPointFormValues) => void
  onCancel: () => void
  loading?: boolean
}

export const PointForm: React.FC<IProps> = ({ point, onSubmit, onCancel, loading }) => {
  const classes = useStyles()
  const initialValues: IPointFormValues = {
    title: point?.title || '',
    deviceCode: point?.deviceCode || '',
  }

  const handleFormSubmit = (values: IPointFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={createSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IPointFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label='Название точки'
            name='title'
            value={values.title}
            onChange={handleChange}
            error={!!errors.title}
            required
          />
          <TextField
            label='Номер устроиства'
            name='deviceCode'
            value={values.deviceCode}
            onChange={handleChange}
            error={!!errors.deviceCode}
            required
          />
          <Box mt={3} display='flex' justifyContent='flex-end'>
            <Box mr={2}>
              <Button onClick={onCancel}>Закрыть</Button>
            </Box>
            <LoadableButton loading={loading} color='primary' variant='contained' type='submit'>
              Сохранить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
}))
