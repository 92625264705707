import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  FormControlLabel,
} from '@mui/material'
import { useSnackbar } from 'notistack'

import { usePointsFilter, useTargetPoints } from '../../../../graphql/hooks/targets'
import { Loader } from '../../../../components/UI'
import { LoadableButton } from '../../../../components/Form'
import {
  IUpdateUserPointsData,
  IUpdateUserPointsInput,
  IUpdateUserPointsVariables,
  IUser,
} from '../../../../graphql/types/users'
import { useStores } from '../../../../stores/hooks'
import { useMutation } from '@apollo/client'
import { TARGET_USERS_QUERY, UPDATE_USER_POINTS_BY_TARGET } from '../../../../graphql/queries/users'
import { usePagination } from '../../../../hooks/usePagination'

interface IProps {
  user: IUser
  onCancel: () => void
}

export const EditUserPointsModal: React.FC<IProps> = ({ user, onCancel }) => {
  const { targetsStore } = useStores()
  const filter = usePointsFilter()
  const { offset, limit } = usePagination()
  const targetId = targetsStore.selectedTarget!.id
  const { points, loading } = useTargetPoints(targetId)
  const [selectedPointsIds, setSelectedPointsIds] = useState<number[]>(user.pointsByTarget.map((up) => up.point.id))
  const [checkAll, setCheckAll] = useState<boolean>(true)
  const [updateUserPoints, { loading: saveLoading }] = useMutation<IUpdateUserPointsData, IUpdateUserPointsVariables>(
    UPDATE_USER_POINTS_BY_TARGET
  )
  const { enqueueSnackbar } = useSnackbar()

  const handleCheckUncheckAll = () => {
    if (checkAll) {
      setSelectedPointsIds(points.map((p) => p.id))
    } else {
      setSelectedPointsIds([])
    }

    setCheckAll((prev) => !prev)
  }

  const handleSelectPoint = (pointId: number) => () => {
    let ids = []

    if (selectedPointsIds.includes(pointId)) {
      ids = selectedPointsIds.filter((p) => p !== pointId)
    } else {
      ids = [...selectedPointsIds, pointId]
    }

    setSelectedPointsIds(ids)
  }

  const handleSubmit = async () => {
    const input: IUpdateUserPointsInput = {
      userId: user.id,
      targetId,
      pointsIds: selectedPointsIds,
    }

    try {
      const { data } = await updateUserPoints({
        variables: { input },
        refetchQueries: [{ query: TARGET_USERS_QUERY, variables: { targetId, filter, pagination: { offset, limit } } }],
      })

      if (data?.updateUserPointsByTarget) {
        enqueueSnackbar('Успешно сохранен!', { variant: 'success' })
        onCancel()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>{user.fullName}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography color='textSecondary'>{user.email}</Typography>
          {!!user.phone && <Typography color='textSecondary'>{user.phone}</Typography>}
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={<Checkbox checked={!checkAll} onChange={handleCheckUncheckAll} name='checkedA' />}
            label={checkAll ? 'Выбрать все' : 'Отключить все'}
          />
        </Box>
        <Box mb={4}>
          {loading && <Loader />}
          {!loading &&
            points.map((point) => (
              <div key={`point-${point.id}`}>
                <ListItem button onClick={handleSelectPoint(point.id)}>
                  <ListItemIcon>
                    <Checkbox edge='start' checked={selectedPointsIds.includes(point.id)} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={`${point.title}`} />
                </ListItem>
                <Divider />
              </div>
            ))}
        </Box>
        <Box mb={2} display='flex' justifyContent='space-between'>
          <Button onClick={onCancel}>Закрыть</Button>
          <LoadableButton loading={saveLoading} color='primary' variant='contained' onClick={handleSubmit}>
            Сохранить
          </LoadableButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
