import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { AuthGuard, GuestGuard } from './guards'
import { DashboardLayout } from './layouts/DashboardLayout'
import { AuthLayout } from './layouts'

import Login from './views/Auth/Login'
import Register from './views/Auth/Register'
import ResetPassword from './views/Auth/ResetPassword'
import Logout from './views/Auth/Logout'
import Home from './views/Home/Home'
import TargetCreate from './views/TargetCreate/TargetCreate'
import Profile from './views/Profile/Profile'
import Points from './views/Target/Ponits/Points'
import Users from './views/Target/Users/Users'
import Inquiries from './views/Target/Inquiries/Inquiries'
import Statistics from './views/Target/Statistics/Statistics'
import Settings from './views/Target/Settings/Settings'
import { HeaderOnlyLayout } from './layouts/HeaderOnlyLayout/HeaderOnlyLayout'

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <AuthGuard>
              <HeaderOnlyLayout />
            </AuthGuard>
          }
        >
          <Route path='profile' element={<Profile />} />
          <Route path='target/create' element={<TargetCreate />} />
        </Route>
        <Route
          element={
            <GuestGuard>
              <AuthLayout />
            </GuestGuard>
          }
        >
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='reset-password' element={<ResetPassword />} />
          <Route path='reset-password/:token' element={<ResetPassword />} />
        </Route>

        <Route
          path='/'
          element={
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          }
        >
          <Route index element={<Navigate to='/home' replace />} />

          <Route path='home' element={<Home />} />

          <Route path='target/:id'>
            <Route index element={<Navigate to='/points' replace />} />
            <Route path='points' element={<Points />} />
            <Route path='users' element={<Users />} />
            <Route path='inquiries' element={<Inquiries />} />
            <Route path='statistics' element={<Statistics />} />
            <Route path='settings' element={<Settings />} />
          </Route>

          <Route path='logout' element={<Logout />} />
        </Route>
        {/* <Route component={PageNotFound} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default Router
