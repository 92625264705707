import { Autocomplete, Box, CircularProgress, debounce, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useFindTargetUsers } from '../../../../graphql/hooks/targets'
import { IUser } from '../../../../graphql/types/users'

interface IProps {
  value?: number
  onSelect: (user: IUser | null) => void
  targetId: number
}

export const UsersAutocomplete: React.FC<IProps> = ({ targetId, value, onSelect }) => {
  const [searchText, setSearchText] = useState<string>('')
  const [users, setUsers] = useState<IUser[]>([])
  const [findUsers] = useFindTargetUsers(targetId)

  const handleSearch = async () => {
    try {
      const { data } = await findUsers({
        variables: {
          filter: {
            search: searchText,
          },
        },
      })

      if (data?.targetUsersWithTotal.users) {
        setUsers(data.targetUsersWithTotal.users)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const delayedUpdateQuery = useCallback(debounce(handleSearch, 500), [searchText])

  useEffect(() => {
    delayedUpdateQuery()

    return delayedUpdateQuery.clear
  }, [searchText])

  return (
    <Autocomplete
      loading
      loadingText={
        <Box textAlign='center'>
          <CircularProgress size={20} />
        </Box>
      }
      disablePortal
      options={users}
      getOptionLabel={(user) => user.fullName}
      onChange={(e, newValue) => onSelect(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          size='small'
          fullWidth
          margin='normal'
          label='Пользователи'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      )}
    />
  )
}
