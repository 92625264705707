import React, { useState } from 'react'
import { Box, Typography, Button, styled } from '@mui/material'
import { Alert } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'

import { PageHeader } from '../../../components/Layout'
import { TargetForm, ITargetFormValues, RegistrationSwitchBox } from '../../../components/Target'
import { useStores } from '../../../stores/hooks'
import { UPDATE_TARGET_MUTATION } from '../../../graphql/queries/targets'
import { ITargetInput, IUpdateTargetData, IUpdateTargetVariables } from '../../../graphql/types/targets'
import { ConfirmDeleteModal } from './components'

const Settings: React.FC = observer(() => {
  const { targetsStore } = useStores()
  const target = targetsStore.selectedTarget
  const { enqueueSnackbar } = useSnackbar()
  const [updateTarget, { error, loading }] = useMutation<IUpdateTargetData, IUpdateTargetVariables>(
    UPDATE_TARGET_MUTATION
  )
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  if (!target) {
    return null
  }

  const handleSubmit = async (values: ITargetFormValues) => {
    const input: ITargetInput = {
      title: values.title,
      cityId: parseInt(values.cityId, 10),
      address: values.address,
    }

    const { data } = await updateTarget({
      variables: { id: target.id, input },
    })

    if (data?.updateTarget) {
      targetsStore.updateTarget(data.updateTarget)
      targetsStore.setSelectedTarget(data.updateTarget)

      enqueueSnackbar('Объект сохранен успешно', { variant: 'success' })
    }
  }

  const toggleDeleteModal = () => {
    setDeleteModalVisible((prev) => !prev)
  }

  return (
    <>
      <PageHeader title='Настройки' />
      <StyledContainer>
        <Box mb={2}>
          <Typography variant='h6'>Объект</Typography>
        </Box>
        <Box mb={4}>
          <RegistrationSwitchBox target={target} />
        </Box>
        <Box mb={6}>
          {!!error && (
            <Box my={2}>
              <Alert severity='error'>{error.message}</Alert>
            </Box>
          )}
          <Box p={2} bgcolor='white'>
            <TargetForm target={target} loading={loading} onSubmit={handleSubmit} />
          </Box>
        </Box>
        <Box>
          <Button variant='outlined' color='secondary' onClick={toggleDeleteModal}>
            Удалить объект
          </Button>
        </Box>
      </StyledContainer>
      {deleteModalVisible && <ConfirmDeleteModal target={target} onCancel={toggleDeleteModal} />}
    </>
  )
})

const StyledContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export default Settings
