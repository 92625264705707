import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../stores/hooks'

interface IProps {
  title: string
  secondary?: string
  action?: ReactNode
}

export const PageHeader: React.FC<IProps> = observer(({ title, action, secondary }) => {
  const { targetsStore } = useStores()

  return (
    <Box mt={3} mb={3}>
      <Box display='flex' alignItems='center'>
        <Box flexGrow={1}>
          <Typography variant='h5'>{title}</Typography>
          <Typography variant='body2' color='text.secondary'>
            {secondary ? secondary : targetsStore.selectedTarget?.title}
          </Typography>
        </Box>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
    </Box>
  )
})
