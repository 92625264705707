import { useState } from 'react'
import { ITargetUsersFilterInput } from '../../types/targets'

export const useUsersFilter = () => {
  const search = ''

  const [filter, setFilter] = useState<ITargetUsersFilterInput>({
    search,
    pointIds: [],
  })

  const changeFilter = (value: Partial<ITargetUsersFilterInput>) => {
    setFilter({ ...filter, ...value })
  }

  return { filter, changeFilter }
}
