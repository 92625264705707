import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import {
  IConfirmUserInquiryData,
  IConfirmUserInquiryInput,
  IConfirmUserInquiryVariables,
  IUserInquiry,
} from '../../../../graphql/types/inquries'
import { DATE_TIME_FORMAT, UserInquiryStatus } from '../../../../core/constants'
import { useTargetPoints } from '../../../../graphql/hooks/targets'
import { Loader } from '../../../../components/UI'
import { LoadableButton } from '../../../../components/Form'
import { CONFIRM_USER_INQUIRY, TARGET_INQUIRIES_QUERY } from '../../../../graphql/queries/inquiries'
import { TARGET_USERS_QUERY } from '../../../../graphql/queries/users'

interface IProps {
  inquiry: IUserInquiry
  onCancel: () => void
}

export const ConfirmInquiryModal: React.FC<IProps> = ({ inquiry, onCancel }) => {
  const { points, loading } = useTargetPoints(inquiry.targetId)
  const [selectedPointsIds, setSelectedPointsIds] = useState<number[]>([])
  const [confirmDecline, setConfirmDecline] = useState<boolean>(false)
  const [rejectionReason, setRejectionReason] = useState<string>('')
  const [confirmInquiry, { loading: confirmLoading }] = useMutation<
    IConfirmUserInquiryData,
    IConfirmUserInquiryVariables
  >(CONFIRM_USER_INQUIRY)

  const handleSelectPoint = (pointId: number) => () => {
    let ids = []

    if (selectedPointsIds.includes(pointId)) {
      ids = selectedPointsIds.filter((p) => p !== pointId)
    } else {
      ids = [...selectedPointsIds, pointId]
    }

    setSelectedPointsIds(ids)
  }

  const handleDecline = () => {
    setConfirmDecline(true)
  }

  const handleCancelDecline = () => {
    setConfirmDecline(false)
  }

  const handleChangeRejectionReason = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setRejectionReason(target.value)
  }

  const submitDecline = async () => {
    if (rejectionReason.length === 0) {
      return
    }

    await submit(UserInquiryStatus.DECLINED)
  }

  const handleAccept = async () => {
    if (selectedPointsIds.length === 0) {
      return
    }

    await submit(UserInquiryStatus.ACCEPTED)
  }

  const submit = async (status: UserInquiryStatus) => {
    const input: IConfirmUserInquiryInput = {
      userId: inquiry.userId,
      targetId: inquiry.targetId,
      pointsIds: selectedPointsIds,
      status,
      rejectionReason:
        status === UserInquiryStatus.DECLINED && rejectionReason.length > 0 ? rejectionReason : undefined,
    }

    await confirmInquiry({
      variables: { input },
      refetchQueries: [
        { query: TARGET_INQUIRIES_QUERY, variables: { targetId: inquiry.targetId } },
        { query: TARGET_USERS_QUERY, variables: { targetId: inquiry.targetId } },
      ],
    })

    onCancel()
  }

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Заявка</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>{inquiry.user.fullName}</Typography>
          <Typography color='textSecondary'>{moment(inquiry.createdAt).format(DATE_TIME_FORMAT)}</Typography>
        </Box>
        <Box mb={4}>
          <Typography>Комментарий</Typography>
          {!!inquiry.comment && <Typography color='textSecondary'>{inquiry.comment}</Typography>}
        </Box>
        {confirmDecline ? (
          <Box mb={4}>
            <TextField
              label='Причина отказа'
              name='rejectionReason'
              value={rejectionReason}
              onChange={handleChangeRejectionReason}
              error={!rejectionReason}
              required
              multiline
              rows={4}
            />
          </Box>
        ) : (
          <Box mb={4}>
            {loading && <Loader />}
            {!loading &&
              points.map((point) => (
                <div key={`point-${point.id}`}>
                  <ListItem button onClick={handleSelectPoint(point.id)}>
                    <ListItemIcon>
                      <Checkbox edge='start' checked={selectedPointsIds.includes(point.id)} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary={`${point.title}`} />
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </Box>
        )}
        <Box mb={2} display='flex' justifyContent='space-between'>
          <Button onClick={onCancel}>Закрыть</Button>
          <Box display='flex'>
            {confirmDecline && (
              <Box mr={2}>
                <Button onClick={handleCancelDecline}>Отмена</Button>
              </Box>
            )}
            <Box mr={2}>
              <LoadableButton
                loading={confirmLoading}
                color='secondary'
                onClick={confirmDecline ? submitDecline : handleDecline}
              >
                Отклонить
              </LoadableButton>
            </Box>
            {!confirmDecline && (
              <LoadableButton
                loading={confirmLoading}
                color='primary'
                variant='contained'
                disabled={selectedPointsIds.length === 0}
                onClick={handleAccept}
              >
                Принять
              </LoadableButton>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
