import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Card, CardContent, Divider, List, Pagination } from '@mui/material'

import { PageHeader } from '../../../components/Layout'
import { EmptyListMessage, Loader } from '../../../components/UI'
import { useTargetUsersWithTotal, useUsersFilter } from '../../../graphql/hooks/targets'
import { usePagination } from '../../../hooks/usePagination'
import { UserItem, UsersToolbar } from './components'

const Users: React.FC = () => {
  const { id } = useParams()
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const { filter, changeFilter } = useUsersFilter()
  const { page, limit, offset, setPage } = usePagination()

  const { users, loading, total } = useTargetUsersWithTotal(parseInt(id || '0', 10), filter, { offset, limit })

  const toggleSelect = (id: number) => {
    setSelectedUsers((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id)
      }
      return [id, ...prev]
    })
  }

  const toggleSelectAll = () => {
    if (selectedUsers.length !== users.length) {
      setSelectedUsers(users.map((item) => item.id))
    } else {
      setSelectedUsers([])
    }
  }

  const clearSelectedUsers = () => {
    setSelectedUsers([])
  }

  return (
    <>
      <Box mt={3}>
        <PageHeader title='Пользователи' />
        <Card>
          <CardContent>
            <UsersToolbar
              toggleAllSelect={toggleSelectAll}
              isAllSelected={!loading && selectedUsers.length === users.length}
              total={total}
              selectedUsers={selectedUsers}
              clearSelectedUsers={clearSelectedUsers}
              filter={filter}
              onChangeFilter={changeFilter}
            />
          </CardContent>
          {loading ? (
            <Loader />
          ) : users.length > 0 ? (
            <>
              <Divider />
              <List component='div' sx={{ p: 0 }}>
                {users.map((user) => (
                  <Box key={`user-${user.id}`}>
                    <UserItem user={user} selected={selectedUsers.includes(user.id)} toggleSelect={toggleSelect} />
                    <Divider />
                  </Box>
                ))}
              </List>
              {total > 0 && Math.ceil(total / limit) > 1 && (
                <Box display='flex' justifyContent='center' p={2}>
                  <Pagination count={Math.ceil(total / limit)} page={page} onChange={(e, value) => setPage(value)} />
                </Box>
              )}
            </>
          ) : (
            <EmptyListMessage message='Пользователей нет' />
          )}
        </Card>
      </Box>
    </>
  )
}

export default Users
