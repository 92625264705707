import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { HttpLink } from '@apollo/client/link/http'
import { WebSocketLink } from '@apollo/client/link/ws'
import { RetryLink } from '@apollo/client/link/retry'
import { getMainDefinition } from '@apollo/client/utilities'

import * as stores from '../stores'
import { stripTypenames } from '../utils/helpers'

const API_URL = process.env.REACT_APP_API_URL
const API_WS_URL = process.env.REACT_APP_API_WS_URL || ''

const httpLink: ApolloLink = new HttpLink({
  uri: API_URL,
})

const authToken = () => (stores.authStore.isLoggedIn ? stores.authStore.token : null)

const wsLink: ApolloLink = new WebSocketLink({
  uri: API_WS_URL,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      return {
        headers: {
          authorization: authToken() ? `Bearer ${authToken()}` : '',
        },
      }
    },
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: Infinity,
    retryIf: (error, operation) => {
      return !!error
    },
  },
})

const authMiddlewareLink: ApolloLink = new ApolloLink((operation, forward) => {
  const token = stores.authStore.token

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  return forward(operation)
})

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const variables = Object.keys(operation.variables)

    if (!variables.includes('file')) {
      operation.variables = stripTypenames(operation.variables, '__typename')
    }
  }

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const isSubscription = operation.query.definitions.some(
    (definition) => definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  )

  if (isSubscription) {
    forward(operation)
  }
  if (graphQLErrors && !isSubscription) {
    graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
      console.log({ extensions })
      if (extensions && (extensions.code === 'NO_SESSION' || extensions.exception?.status === 401)) {
        stores.authStore.logout()
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, retryLink, authMiddlewareLink, removeTypenameMiddleware, splitLink]),
  cache: new InMemoryCache(),
})
