import React from 'react'
import { ListItem, ListItemText } from '@mui/material'
import moment from 'moment'

import { IUserInquiry } from '../../../../graphql/types/inquries'
import { DATE_TIME_FORMAT } from '../../../../core/constants'

interface IProps {
  inquiry: IUserInquiry
  onClick: () => void
}

export const InquiryItem: React.FC<IProps> = ({ inquiry, onClick }) => {
  return (
    <ListItem button onClick={onClick}>
      <ListItemText primary={inquiry.user.fullName} secondary={moment(inquiry.createdAt).format(DATE_TIME_FORMAT)} />
    </ListItem>
  )
}
