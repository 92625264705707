import React from 'react'
import { Box, Container, Grid, styled, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { logoImg, signUpImg } from '../assets/images'

export const AuthLayout: React.FC = () => {
  return (
    <Main>
      <Container maxWidth='md'>
        <Box>
          <Box display='flex' alignItems='center' justifyContent='center' mb={3}>
            <Box mr={1}>
              <img src={logoImg} width={50} alt='Relon' />
            </Box>
            <Typography variant='h4'>RELON</Typography>
          </Box>
        </Box>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <img src={signUpImg} alt='Relon' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </Main>
  )
}

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '100vh',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}))
