import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { ROWS_PER_PAGE } from '../../../core/constants'
import { GET_ACCESS_LOGS_BY_TARGET } from '../../queries/access-logs'
import { IAccessLogFilter, IGetAccessLogsByTargetData, IGetAccessLogsByTargetVariables } from '../../types/access-logs'
import { IPaginationInput } from '../../types/common'

export const useAccessLogs = (targetId: number, filter: IAccessLogFilter) => {
  const [page, setPage] = useState<number>(0)
  const [pagination, setPagination] = useState<IPaginationInput>({ offset: 0, limit: ROWS_PER_PAGE[0] })

  const { data, loading } = useQuery<IGetAccessLogsByTargetData, IGetAccessLogsByTargetVariables>(
    GET_ACCESS_LOGS_BY_TARGET,
    {
      variables: { targetId, filter, pagination },
      fetchPolicy: 'network-only',
    }
  )

  const handleChangePerPage = (limit: number) => {
    if (limit !== pagination.limit) {
      setPage(0)
      setPagination({ offset: 0, limit })
    }
  }

  const handleChangePage = (page: number) => {
    setPage(page)
    setPagination({ ...pagination, offset: page * pagination.limit })
  }

  const accessLogs = data?.accessLogsByTarget.accessLogs || []
  const total = data?.accessLogsByTarget.total || 0

  return {
    page,
    pagination,
    handleChangePage,
    handleChangePerPage,
    accessLogs,
    total,
    loading,
  }
}
