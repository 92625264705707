import React from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Box, Button, Divider, Menu, MenuItem, styled, Typography } from '@mui/material'
import ArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { AddCircle as AddIcon } from '@mui/icons-material'

import { useStores } from '../../stores/hooks'
import { ITarget } from '../../graphql/types/targets'

interface IProps {
  toggleCreateDialog: VoidFunction
}

export const HeaderTargetsMenu: React.FC<IProps> = observer(({ toggleCreateDialog }) => {
  const navigate = useNavigate()
  const { targetsStore } = useStores()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMenu = (target: ITarget) => () => {
    targetsStore.setSelectedTarget(target)
    handleClose()
    navigate(`/target/${target.id}/points`)
  }

  const handleClickMenuItem = () => {
    toggleCreateDialog()
    setAnchorEl(null)
  }

  if (!(targetsStore.hasTargets && targetsStore.selectedTarget)) {
    return null
  }

  return (
    <div style={{ position: 'relative' }}>
      <Box>
        <ButtonStyle color='inherit' onClick={handleClick}>
          <Box textAlign='left' lineHeight='0'>
            <Typography noWrap sx={{ fontSize: 18, fontWeight: 700, mb: '-0.3rem' }}>
              {targetsStore.selectedTarget.title}
            </Typography>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {targetsStore.selectedTarget.address.address}
            </Typography>
          </Box>
          <ArrowRight />
        </ButtonStyle>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            maxWidth: 300,
          },
        }}
      >
        {targetsStore.targets.map((target) => {
          const isDisabled = target.id === targetsStore.selectedTarget!.id

          return isDisabled ? (
            <MenuItem key={`targetMenu-${target.id}`} disabled>
              <Typography noWrap>{target.title}</Typography>
            </MenuItem>
          ) : (
            <MenuItem key={`targetMenu-${target.id}`} onClick={handleClickMenu(target)}>
              <Typography noWrap>{target.title}</Typography>
            </MenuItem>
          )
        })}
        <Divider />
        <MenuItem onClick={handleClickMenuItem}>
          <AddIcon color='primary' fontSize='small' sx={{ mr: 1 }} />
          <Typography color='primary'>Создать объект</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
})

const ButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255, 10%)',
  color: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(3.5),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255,255,255, 15%)',
  },
}))
