import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Container } from '@mui/material'

import { DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE, DASHBOARD_NAVBAR_WIDTH } from '../sizes'
import HeaderOnlyHeader from './header'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    minHeight: '100%',
  },
}))

const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: DASHBOARD_HEADER_MOBILE + 24,
  paddingBottom: DASHBOARD_HEADER_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingRight: 16,
    paddingTop: DASHBOARD_HEADER_DESKTOP + 24,
    paddingBottom: DASHBOARD_HEADER_DESKTOP + 24,
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

// ----------------------------------------------------------------------

export function HeaderOnlyLayout() {
  return (
    <RootStyle>
      <HeaderOnlyHeader />

      <MainStyle>
        <Container>
          <Outlet />
        </Container>
      </MainStyle>
    </RootStyle>
  )
}
