import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  Menu,
  MenuItem,
  Box,
  Checkbox,
  Tooltip,
  Typography,
} from '@mui/material'
import { MoreHoriz as MenuIcon, RemoveCircleOutline as SuspendedIcon } from '@mui/icons-material'

import { useMutation } from '@apollo/client'

import { IPoint, ISwitchPointSuspensionData, ISwitchPointSuspensionVariables } from '../../../../graphql/types/points'
import { EditPointModal } from './EditPointModal'
import { SWITCH_POINT_SUSPENSION_MUTATION, TARGET_POINTS_QUERY } from '../../../../graphql/queries/points'
import { PointStatus } from './PointStatus'
import { useDeletePoints } from '../../../../graphql/hooks/targets/useDeletePoints'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import { useSnackbar } from 'notistack'
import { usePagination } from '../../../../hooks/usePagination'
import { usePointsFilter } from '../../../../graphql/hooks/targets'

interface IProps {
  point: IPoint
  selected: boolean
  toggleSelect: (id: number) => void
}

export const PointItem: React.FC<IProps> = ({ point, selected, toggleSelect }) => {
  const { id } = useParams()
  const filter = usePointsFilter()
  const { offset, limit } = usePagination()
  const confirmDialog = useConfirmDialog()
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
  const [switchSuspension] = useMutation<ISwitchPointSuspensionData, ISwitchPointSuspensionVariables>(
    SWITCH_POINT_SUSPENSION_MUTATION
  )
  const [deletePoint] = useDeletePoints(parseInt(id || '0', 10))

  const handleDeletePoint = async () => {
    try {
      await confirmDialog({ message: 'Вы действительно хотите удалить точку?' })
    } catch {
      return
    }

    try {
      await deletePoint({
        variables: {
          ids: [point.id],
        },
      })
    } catch (error) {
      console.error(error)
    }

    enqueueSnackbar('Точка удалена', {
      variant: 'success',
    })
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const toggleEditModal = () => {
    setEditModalVisible((prev) => !prev)
    handleCloseMenu()
  }

  const handleSwitchSuspension = async () => {
    await switchSuspension({
      variables: {
        id: point.id,
        suspended: !point.isSuspended,
      },
      refetchQueries: [
        { query: TARGET_POINTS_QUERY, variables: { targetId: point.targetId, filter, pagination: { offset, limit } } },
      ],
    })

    enqueueSnackbar('Статус изменен', {
      variant: 'success',
    })

    handleCloseMenu()
  }

  return (
    <>
      <ListItem disabled={!point.isActivated || point.isSuspended} selected={selected}>
        <Checkbox checked={selected} onChange={() => toggleSelect(point.id)} />
        {!point.isSuspended && point.isActivated && (
          <Box mr={2} ml={2}>
            <PointStatus point={point} />
          </Box>
        )}
        {point.isSuspended && (
          <Box ml={1.5} mr={1.5}>
            <Tooltip title='Приостановлен' placement='top'>
              <ListItemAvatar sx={{ minWidth: 0 }}>
                <SuspendedIcon fontSize='small' />
              </ListItemAvatar>
            </Tooltip>
          </Box>
        )}
        <Box>
          <Typography variant='subtitle1' fontWeight={700}>
            {point.title}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {point.deviceCode}
          </Typography>
        </Box>
        <ListItemSecondaryAction>
          <IconButton onClick={handleClickMenu} size='large'>
            <MenuIcon color='disabled' />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <MenuItem onClick={handleSwitchSuspension}>{point.isSuspended ? 'Запустить' : 'Приостановить'}</MenuItem>
            <MenuItem onClick={toggleEditModal}>Редактировать</MenuItem>
            <MenuItem onClick={handleDeletePoint}>Удалить</MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      {editModalVisible && <EditPointModal point={point} onCancel={toggleEditModal} />}
    </>
  )
}
