import * as yup from 'yup'

import { PHONE_REGEXP } from '../../../core/constants'

export const registerSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(3).required(),
  phone: yup.string().trim().matches(PHONE_REGEXP, 'Не правильный формат телефона').required(),
})

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const newPasswordSchema = yup.object().shape({
  password: yup.string().min(2).required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password')])
    .required(),
})
