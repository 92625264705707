import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Stack, Drawer, Divider } from '@mui/material'

import { IBusinessTargetData, IBusinessTargetVariables } from '../../../graphql/types/targets'
import { TARGET_QUERY } from '../../../graphql/queries/targets'

import useResponsive from '../../../graphql/hooks/useResponsive'
import { DASHBOARD_NAVBAR_WIDTH } from '../../sizes'
import { HeaderTargetsMenu, TargetMenu } from '../../../components/Menu'
import Logo from '../../../components/UI/logo'
import { Loader } from '../../../components/UI'
import { useStores } from '../../../stores/hooks'
import { CreateTargetDialog } from './CreateTargetDialog'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

type Props = {
  isOpenSidebar: boolean
  onCloseSidebar: VoidFunction
}

export default function DashboardNavbar({ isOpenSidebar, onCloseSidebar }: Props) {
  const { id } = useParams()
  const { pathname } = useLocation()
  const isDesktop = useResponsive('up', 'lg')
  const { targetsStore } = useStores()
  const [createDialog, setCreateDialog] = useState<boolean>(false)

  const toggleCreateDialog = () => {
    setCreateDialog(!createDialog)
  }

  const { data, loading } = useQuery<IBusinessTargetData, IBusinessTargetVariables>(TARGET_QUERY, {
    variables: { id: parseInt(id || '0', 10) },
  })
  const target = data?.businessTarget

  useEffect(() => {
    if (target && target.id !== targetsStore.selectedTarget?.id) {
      targetsStore.setSelectedTarget(target)
    }

    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, target?.id])

  if (loading) {
    return <Loader />
  }

  if (!target) {
    return null
  }

  const renderContent = (
    <>
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2,
        }}
      >
        <Logo />
        <HeaderTargetsMenu toggleCreateDialog={toggleCreateDialog} />
      </Stack>
      <Divider sx={{ bgcolor: 'rgba(255,255,255, 30%)' }} />
      <Box mt={3} sx={{ color: 'white' }}>
        <TargetMenu targetId={target.id} />
      </Box>
    </>
  )

  return (
    <RootStyle>
      <Drawer
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        variant={isDesktop ? 'permanent' : 'temporary'}
        anchor='left'
        PaperProps={{
          sx: {
            width: DASHBOARD_NAVBAR_WIDTH,
            bgcolor: (theme) => theme.palette.primary.main,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
          },
        }}
      >
        {renderContent}
      </Drawer>
      {createDialog && <CreateTargetDialog open onClose={toggleCreateDialog} />}
    </RootStyle>
  )
}
