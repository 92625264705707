import { useMutation } from '@apollo/client'
import { usePagination } from '../../../hooks/usePagination'

import { SUSPEND_POINTS_MUTATION, TARGET_POINTS_QUERY } from '../../queries/points'
import { IDeletePointsData, IDeletePointsVariables } from '../../types/points'
import { usePointsFilter } from './usePointsFilter'

export const useSuspendPoints = (targetId: number) => {
  const filter = usePointsFilter()
  const { offset, limit } = usePagination()

  return useMutation<IDeletePointsData, IDeletePointsVariables>(SUSPEND_POINTS_MUTATION, {
    refetchQueries: [
      {
        query: TARGET_POINTS_QUERY,
        variables: {
          targetId,
          filter,
          pagination: {
            offset,
            limit,
          },
        },
      },
    ],
  })
}
