import React from 'react'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'

interface IProps {
  size?: number
}

export const Loader: React.FC<IProps> = ({ size = 30 }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color='inherit' size={size} thickness={4} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    color: grey[600],
  },
}))
