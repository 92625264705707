import React from 'react'
import { TextField, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../components/Form'
import { resetPasswordSchema } from './validation'

export interface IResetPasswordFormValues {
  email: string
}

interface IProps {
  onSubmit: (values: IResetPasswordFormValues) => void
  loading?: boolean
}

export const ResetPasswordForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const classes = useStyles()

  const initialValues: IResetPasswordFormValues = {
    email: '',
  }

  const handleFormSubmit = (values: IResetPasswordFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={resetPasswordSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IResetPasswordFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={handleChange}
            error={!!errors.email}
            required
          />
          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Отправить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}))
