import { useMutation } from '@apollo/client'
import { Box, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Alert } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { LOGIN_BUSINESS_MUTATION } from '../../graphql/queries/business'
import { ILoginBusinessData, ILoginBusinessVariables } from '../../graphql/types/business'
import { useStores } from '../../stores/hooks'
import { ILoginFormValues, LoginForm } from './components'

function Login() {
  const classes = useStyles()
  const { authStore } = useStores()
  const navigate = useNavigate()
  const [loginBusiness, { loading, error }] = useMutation<ILoginBusinessData, ILoginBusinessVariables>(
    LOGIN_BUSINESS_MUTATION
  )

  const handleSubmit = async (values: ILoginFormValues) => {
    try {
      const { data } = await loginBusiness({
        variables: { ...values },
      })

      const business = data?.loginBusiness.business
      const token = data?.loginBusiness.token

      if (business && token) {
        authStore.login(business, token)
        navigate('/')
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant='h5'>Вход</Typography>
        {!!error && (
          <Box my={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <LoginForm loading={loading} onSubmit={handleSubmit} />
      </Paper>
      <Box mt={3} textAlign='center'>
        <Typography>
          Еще нет аккаунта? <Link to='/register'>Регистрация</Link>
        </Typography>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}))

export default Login
