import { ObservableSubscription } from '@apollo/client'
import { makeObservable, observable, action, computed, reaction, IReactionDisposer } from 'mobx'

import { client } from '../core/apollo'
import { POINTS_STATUSES_QUERY, POINTS_STATUSES_SUBSCRIPTION } from '../graphql/queries/points'
import { TARGETS_QUERY } from '../graphql/queries/targets'
import {
  IGetPointsStatusesData,
  IGetPointsStatusesVariables,
  IPointsStatusesSubscriptionData,
  IPointsStatusesSubscriptionVariables,
  IPointStatus,
} from '../graphql/types/points'
import { ITarget, ITargetsData } from '../graphql/types/targets'
import authStore from './AuthStore'

export class TargetsStore {
  targets: ITarget[] = []
  selectedTarget: ITarget | null = null
  pointsStatuses: IPointStatus[] = []
  loading: boolean = false
  subcriber?: ObservableSubscription

  private disposers: IReactionDisposer[] = []

  constructor() {
    makeObservable(this, {
      targets: observable,
      setTargets: action,
      addTarget: action,
      selectedTarget: observable,
      setSelectedTarget: action,
      pointsStatuses: observable,
      setPointsStatuses: action,
      loading: observable,
      setLoading: action,
      hasTargets: computed,
      updateTarget: action,
    })

    if (authStore.isLoggedIn) {
      this.loadTargets()
    }

    this.disposers.push(
      reaction(
        () => authStore.isLoggedIn,
        (isLoggedIn) => {
          if (isLoggedIn) {
            this.loadTargets()
          }
        }
      )
    )

    this.disposers.push(
      reaction(
        () => !!authStore.isLoggedIn && !!this.selectedTarget,
        async (authAndSelectedTarget) => {
          if (authAndSelectedTarget) {
            await this.loadPointsStatuses()
            this.subscribeStatus()
          }
        },
        { fireImmediately: true }
      )
    )
  }

  get hasTargets() {
    return this.targets.length > 0
  }

  addTarget(target: ITarget) {
    this.targets.push(target)
    this.setSelectedTarget(target)
  }

  setSelectedTarget(target: ITarget) {
    this.selectedTarget = target
  }

  updateTarget(target: ITarget) {
    const index = this.targets.findIndex((t) => t.id === target.id)

    if (index > -1) {
      this.targets[index] = target
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  setTargets(targets: ITarget[]) {
    this.targets = targets
  }

  async reloadTargets() {
    await this.loadTargets(true)
  }

  async loadTargets(reset = false) {
    this.setLoading(true)

    const { data } = await client.query<ITargetsData, {}>({
      query: TARGETS_QUERY,
      fetchPolicy: 'network-only',
    })

    this.setTargets(data.businessTargets.targets)

    if (this.targets.length > 0 && (reset || this.selectedTarget === null)) {
      this.setSelectedTarget(this.targets[0])
    }

    this.setLoading(false)
  }

  setPointsStatuses(statuses: IPointStatus[]) {
    this.pointsStatuses = [
      ...this.pointsStatuses.filter((status) => !statuses.find((s) => s.deviceCode === status.deviceCode)),
      ...statuses,
    ]
  }

  async subscribeStatus() {
    if (this.selectedTarget && authStore.isLoggedIn) {
      this.subcriber?.unsubscribe()
      this.subcriber = client
        .subscribe<IPointsStatusesSubscriptionData, IPointsStatusesSubscriptionVariables>({
          query: POINTS_STATUSES_SUBSCRIPTION,
          variables: { targetId: this.selectedTarget.id },
        })
        .subscribe(
          ({ data }) => {
            if (data?.pointsStatusesByTarget != null) {
              this.setPointsStatuses([data.pointsStatusesByTarget])
            }
          },
          (error) => {
            console.error(error)
          }
        )
    }
  }

  async loadPointsStatuses() {
    if (!this.selectedTarget) {
      return
    }

    this.setLoading(true)

    const { data } = await client.query<IGetPointsStatusesData, IGetPointsStatusesVariables>({
      query: POINTS_STATUSES_QUERY,
      variables: { targetId: this.selectedTarget.id },
      fetchPolicy: 'network-only',
    })

    this.setPointsStatuses(data.getPointsStatusByTarget)

    this.setLoading(false)
  }

  dispose() {
    this.disposers.map((disposer) => disposer())
  }
}

export default new TargetsStore()
