import React from 'react'
import { Box, Typography } from '@mui/material'

import { IAccessLog } from '../../../../graphql/types/access-logs'

interface IProps {
  item: IAccessLog
}

export const UserColumn: React.FC<IProps> = ({ item }) => {
  return (
    <Box>
      <Typography variant='body2'>{item.user.fullName}</Typography>
      <Typography variant='caption' color='textSecondary'>
        {item.user.email}
      </Typography>
      {!!item.user.phone && (
        <Typography variant='caption' color='textSecondary'>
          {' | '}
          {item.user.phone}
        </Typography>
      )}
    </Box>
  )
}
