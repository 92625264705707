import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import DashboardHeader from './header'
import DashboardNavbar from './navbar'
import { Container } from '@mui/material'
import { DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE, DASHBOARD_NAVBAR_WIDTH } from '../sizes'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    minHeight: '100%',
  },
}))

const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: DASHBOARD_HEADER_MOBILE + 24,
  paddingBottom: DASHBOARD_HEADER_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: DASHBOARD_NAVBAR_WIDTH + 16,
    paddingRight: 16,
    paddingTop: DASHBOARD_HEADER_DESKTOP + 24,
    paddingBottom: DASHBOARD_HEADER_DESKTOP + 24,
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}))

// ----------------------------------------------------------------------

export function DashboardLayout() {
  const [open, setOpen] = useState(false)

  return (
    <RootStyle>
      <DashboardHeader onOpenSidebar={() => setOpen(true)} />

      <DashboardNavbar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>
        <Container>
          <Outlet />
        </Container>
      </MainStyle>
    </RootStyle>
  )
}
