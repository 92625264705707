import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { AccountCircle as AccountIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export const HeaderMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton color='default' onClick={handleClick} size='large'>
        <AccountIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem onClick={handleClose} component={Link} to='/profile'>
          Профиль
        </MenuItem>
        <MenuItem component={Link} to='/logout'>
          Выйти
        </MenuItem>
      </Menu>
    </div>
  )
}
