import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTargetPoints } from '../../../../graphql/hooks/targets'
import { usePagination } from '../../../../hooks/usePagination'

interface IProps {
  value: number[]
  onChange: (value: number[]) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

export const MultiplePointsSelect = ({ value, onChange }: IProps) => {
  const { id } = useParams()
  const { offset, limit } = usePagination(50)

  const { points, loading } = useTargetPoints(parseInt(id || '0', 10), {}, { offset, limit })

  const handleSearchByPoints = (ids: number[]) => {
    onChange(ids)
  }

  return (
    <FormControl sx={{ ml: 2, width: 190 }} size='small'>
      <InputLabel>Точка</InputLabel>
      <Select
        multiple
        value={value}
        onChange={(e) => handleSearchByPoints(e.target.value as number[])}
        label='Точка'
        size='small'
        renderValue={(selected) => selected.map((item) => points.find((point) => point.id === item)!.title).join(', ')}
        MenuProps={MenuProps}
        disabled={loading}
      >
        {points.map((point) => (
          <MenuItem key={point.id} value={point.id}>
            <Checkbox checked={value.includes(point.id)} />
            <ListItemText primary={point.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
