import gql from 'graphql-tag'

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    fullName
    phone
  }
`

export const TARGET_USERS_QUERY = gql`
  query GetTargetUsersQuery($targetId: Int!) {
    targetUsers(targetId: $targetId) {
      ...UserFragment
      pointsByTarget(targetId: $targetId) {
        point {
          id
          title
        }
        name
        isActive
        createdAt
      }
    }
  }
  ${USER_FRAGMENT}
`

export const UPDATE_USER_POINTS_BY_TARGET = gql`
  mutation UpdateUserPointsByTargetMutation($input: UpdateUserPointsInput!) {
    updateUserPointsByTarget(input: $input)
  }
`
