import React from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableCellProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

type AccessorFunctionType<ItemT> = (item: ItemT) => string | React.ReactNode
type DataTableElement = <ItemT extends { [key: string]: any }>(p: IProps<ItemT>) => React.ReactElement<IProps<ItemT>>

export interface IDataTableColumn<ItemT> extends TableCellProps {
  label: string
  accessor: string | AccessorFunctionType<ItemT>
}

interface IProps<ItemT> {
  data: ItemT[]
  columns: IDataTableColumn<ItemT>[]
}

export const DataTable: DataTableElement = ({ data, columns }) => {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {columns.map(({ label, accessor, ...cellProps }, index) => (
              <TableCell key={`cell-${index}`} {...cellProps}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={`row-${index}`}>
              {columns.map(({ label, accessor, ...cellProps }, index) => (
                <TableCell key={`row-cell-${index}`} {...cellProps}>
                  {typeof accessor === 'function' ? accessor(item) : item[accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}))
