import React from 'react'
import { TextField, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { LoadableButton } from '../../../components/Form'
import { newPasswordSchema } from './validation'
import { useMutation } from '@apollo/client'
import { RESET_PASSWORD_MUTATION } from '../../../graphql/queries/business'
import { IResetPasswordData, IResetPasswordVariables } from '../../../graphql/types/business'

export interface INewPasswordFormValues {
  password: string
  passwordConfirmation: string
}

interface IProps {
  resetToken: string
}

export const NewPasswordForm: React.FC<IProps> = ({ resetToken }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [resetPassword, { loading }] = useMutation<IResetPasswordData, IResetPasswordVariables>(RESET_PASSWORD_MUTATION)

  const initialValues: INewPasswordFormValues = {
    password: '',
    passwordConfirmation: '',
  }

  const handleFormSubmit = async (values: INewPasswordFormValues) => {
    try {
      const { data } = await resetPassword({
        variables: { token: resetToken, newPassword: values.password },
      })

      if (data?.resetPasswordByBusiness) {
        enqueueSnackbar('Парлоль успешно изменен!', { variant: 'success' })
        navigate('/login')
      } else {
        enqueueSnackbar('Ошибка при изменении пароля', { variant: 'error' })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={newPasswordSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<INewPasswordFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label='Новый пароль'
            name='password'
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
            required
            type='password'
          />
          <TextField
            label='Повторите пароль'
            name='passwordConfirmation'
            value={values.passwordConfirmation}
            onChange={handleChange}
            error={!!errors.passwordConfirmation}
            required
            type='password'
          />
          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Сохранить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}))
