import React from 'react'
import { TextField, Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikProps } from 'formik'
import { Link } from 'react-router-dom'

import { LoadableButton } from '../../../components/Form'
import { loginSchema } from './validation'

export interface ILoginFormValues {
  email: string
  password: string
}

interface IProps {
  onSubmit: (values: ILoginFormValues) => void
  loading?: boolean
}

export const LoginForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const classes = useStyles()

  const initialValues: ILoginFormValues = {
    email: '',
    password: '',
  }

  const handleFormSubmit = (values: ILoginFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<ILoginFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={handleChange}
            error={!!errors.email}
            required
          />
          <TextField
            label='Пароль'
            name='password'
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
            required
            type='password'
          />
          <Box textAlign='right'>
            <Typography>
              <Link to='/reset-password'>Забыли пароль?</Link>
            </Typography>
          </Box>
          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Войти
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}))
