import { useMutation } from '@apollo/client'
import { Box, styled } from '@mui/material'
import { Alert } from '@mui/material'
import { useNavigate } from 'react-router'

import { PageHeader } from '../../components/Layout'
import { CREATE_TARGET_MUTATION, TARGETS_QUERY } from '../../graphql/queries/targets'
import { ICreateTargetData, ICreateTargetVariables, ITargetInput } from '../../graphql/types/targets'
import { useStores } from '../../stores/hooks'
import { TargetForm, ITargetFormValues } from '../../components/Target'

function TargetCreate() {
  const navigate = useNavigate()
  const { targetsStore } = useStores()

  const [createTarget, { loading, error }] = useMutation<ICreateTargetData, ICreateTargetVariables>(
    CREATE_TARGET_MUTATION
  )

  const handleSubmit = async (values: ITargetFormValues) => {
    try {
      const input: ITargetInput = {
        title: values.title,
        cityId: parseInt(values.cityId, 10),
        address: values.address,
      }

      const { data } = await createTarget({
        variables: { input },
        refetchQueries: [{ query: TARGETS_QUERY }],
      })

      const target = data?.createTarget

      if (target) {
        targetsStore.addTarget(target)
        navigate(`/target/${target.id}/points`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <StyledContainer>
      <Box>
        <PageHeader title='Новый объект' />
        {!!error && (
          <Box my={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <Box p={2} bgcolor='white'>
          <TargetForm loading={loading} onSubmit={handleSubmit} />
        </Box>
      </Box>
    </StyledContainer>
  )
}

const StyledContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export default TargetCreate
