import { AppBar, Box, IconButton, Stack, styled, Toolbar } from '@mui/material'
import useResponsive from '../../../graphql/hooks/useResponsive'
import { DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE, DASHBOARD_NAVBAR_WIDTH } from '../../sizes'
import MenuIcon from '@mui/icons-material/Menu'
import { HeaderMenu } from '../../../components/Menu'

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH + 1}px)`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
    minHeight: DASHBOARD_HEADER_DESKTOP,
  },
}))

interface IProps {
  onOpenSidebar: VoidFunction
}

export default function DashboardHeader({ onOpenSidebar }: IProps) {
  const isDesktop = useResponsive('up', 'lg')

  return (
    <RootStyle>
      <ToolbarStyle>
        {!isDesktop && (
          <IconButton onClick={onOpenSidebar}>
            <MenuIcon />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
          <HeaderMenu />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}
