import React from 'react'
import { TextField, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikProps } from 'formik'

import { LoadableButton, PhoneField } from '../../../components/Form'
import { registerSchema } from './validation'

export interface IRegisterFormValues {
  name: string
  email: string
  password: string
  phone: string
}

interface IProps {
  onSubmit: (values: IRegisterFormValues) => void
  loading?: boolean
}

export const RegisterForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const classes = useStyles()
  const initialValues: IRegisterFormValues = {
    name: '',
    email: '',
    password: '',
    phone: '',
  }

  const handleFormSubmit = (values: IRegisterFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={registerSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, setFieldValue, handleChange, handleSubmit }: FormikProps<IRegisterFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label='Имя'
            name='name'
            value={values.name}
            onChange={handleChange}
            error={!!errors.name}
            required
          />
          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={handleChange}
            error={!!errors.email}
            required
          />
          <TextField
            label='Пароль'
            name='password'
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
            required
            type='password'
          />
          <PhoneField
            label='Телефон'
            name='phone'
            value={values.phone}
            onChange={(e) => setFieldValue('phone', `+${e.target.value.replace(/\D+/g, '')}`)}
            error={!!errors.phone}
            required
          />
          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Зарегистрироваться
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
}))
