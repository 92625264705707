import { Typography } from '@mui/material'
import moment from 'moment'

import { IDataTableColumn } from '../../../../components/Data'
import { DATE_TIME_FORMAT } from '../../../../core/constants'
import { IAccessLog } from '../../../../graphql/types/access-logs'
import { UserColumn } from './UserColumn'

export const columns: IDataTableColumn<IAccessLog>[] = [
  {
    label: 'Дата',
    accessor: (item) => (
      <Typography variant='caption' color='textSecondary'>
        {moment(item.createdAt).format(DATE_TIME_FORMAT)}
      </Typography>
    ),
  },
  { label: 'Пользователь', accessor: (item) => <UserColumn item={item} /> },
  { label: 'Точка', accessor: (item) => item.point.title },
]
