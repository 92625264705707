import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Loader } from '../../components/UI'
import { Navigate } from 'react-router-dom'
import { useStores } from '../../stores/hooks'

function Home() {
  const { targetsStore } = useStores()

  if (targetsStore.loading) {
    return (
      <Box mt={3}>
        <Loader />
      </Box>
    )
  }

  if (targetsStore.targets.length === 0) {
    return <Navigate to={`/target/create`} replace />
  }

  if (targetsStore.selectedTarget) {
    return <Navigate to={`/target/${targetsStore.selectedTarget.id}/points`} replace />
  }

  return null
}

export default observer(Home)
