import React, { Fragment } from 'react'
import { Box, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { grey } from '@mui/material/colors'
import { DatePicker } from '@mui/x-date-pickers'
import { debounce } from 'lodash'
import moment from 'moment'

import { IAccessLogFilter } from '../../../../graphql/types/access-logs'
import { useTargetPoints } from '../../../../graphql/hooks/targets'
import { DATE_FORMAT } from '../../../../core/constants'
import { IUser } from '../../../../graphql/types/users'
import { UsersAutocomplete } from './UsersAutocomplete'

interface IProps {
  targetId: number
  filter: IAccessLogFilter
  onChange: (filter: IAccessLogFilter) => void
}

export const StatisticsFilter: React.FC<IProps> = ({ targetId, filter, onChange }) => {
  // const { users } = useTargetUsers(targetId)
  const { points } = useTargetPoints(targetId)

  const [userId, setUserId] = React.useState<number | string>(
    filter.userId === undefined ? 'all' : Number(filter.userId)
  )
  const [pointId, setPointId] = React.useState<number | string>(
    filter.pointId === undefined ? 'all' : Number(filter.pointId)
  )
  const [startDate, setStartDate] = React.useState<string | null>(filter.period?.startDate || null)
  const [endDate, setEndDate] = React.useState<string | null>(filter.period?.endDate || null)

  const updateFilter = () => {
    onChange({
      userId: userId === 'all' ? undefined : Number(userId),
      pointId: pointId === 'all' ? undefined : Number(pointId),
      period: {
        startDate: startDate ? moment(startDate).startOf('day').toISOString() : undefined,
        endDate: endDate ? moment(endDate).endOf('day').toISOString() : undefined,
      },
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedUpdateQuery = React.useCallback(debounce(updateFilter, 500), [userId, pointId, startDate, endDate])

  const handleChangeUser = (user: IUser | null) => {
    setUserId(user ? user.id : 'all')
  }

  const handleChangePoint = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setPointId(target.value)
  }

  React.useEffect(() => {
    delayedUpdateQuery()

    return delayedUpdateQuery.cancel
  }, [userId, pointId, startDate, endDate, delayedUpdateQuery])

  return (
    <Fragment>
      <Divider />
      <Box paddingX={2} paddingY={1} bgcolor={grey[50]}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <UsersAutocomplete onSelect={handleChangeUser} targetId={targetId} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Точка'
              variant='outlined'
              size='small'
              onChange={handleChangePoint}
              fullWidth
              margin='normal'
              select
              value={pointId}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              {points.map((p) => (
                <MenuItem key={`poitn-select-${p.id}`} value={p.id}>
                  {p.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              label='Дата с'
              value={startDate}
              onChange={(date) => setStartDate(date || null)}
              inputFormat={DATE_FORMAT}
              renderInput={(params) => <TextField {...params} variant='outlined' fullWidth size='small' />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              label='Дата по'
              value={endDate}
              onChange={(date) => setEndDate(date || null)}
              inputFormat={DATE_FORMAT}
              renderInput={(params) => <TextField {...params} variant='outlined' fullWidth size='small' />}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}
