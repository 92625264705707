import gql from 'graphql-tag'

export const GET_ACCESS_LOGS_BY_TARGET = gql`
  query GetAccessLogsByTargetQuery($targetId: Int!, $filter: AccessLogFilterInput, $pagination: PaginationInput) {
    accessLogsByTarget(targetId: $targetId, filter: $filter, pagination: $pagination) {
      accessLogs {
        id
        user {
          id
          email
          fullName
          phone
        }
        target {
          id
          title
        }
        point {
          id
          title
          deviceCode
        }
        userDeviceToken
        status
        createdAt
      }
      total
    }
  }
`
