import React, { useState } from 'react'
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
  Stack,
  Chip,
} from '@mui/material'
import { MoreHoriz as MenuIcon } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'

import { IUser } from '../../../../graphql/types/users'
import { EditUserPointsModal } from './EditUserPointsModal'

interface IProps {
  user: IUser
  selected: boolean
  toggleSelect: (id: number) => void
}

export const UserItem: React.FC<IProps> = ({ user, selected, toggleSelect }) => {
  const LENGTH = 10
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const toggleEditModal = () => {
    setEditModalVisible((prev) => !prev)
    handleCloseMenu()
  }

  return (
    <>
      <ListItem selected={selected}>
        <Checkbox checked={selected} onChange={() => toggleSelect(user.id)} />
        <Stack ml={1}>
          <Typography variant='subtitle1' fontWeight={700}>
            {user.fullName}
          </Typography>
          <Typography variant='body2' color='text.secondary' mb={0.5}>
            {user.email}
          </Typography>
          <Stack direction='row' alignItems='center' spacing={1} mb={1}>
            {user.pointsByTarget.slice(0, 3).map((item) => (
              <Chip
                key={item.point.id}
                title={item.point.title}
                label={item.point.title.length > LENGTH ? item.point.title.substring(0, 10) + '...' : item.point.title}
                size='small'
                icon={
                  !item.point.isSuspended ? (
                    <CheckCircleOutlineIcon fontSize='small' color='inherit' />
                  ) : (
                    <PauseCircleOutlineIcon fontSize='small' color='inherit' />
                  )
                }
                sx={{
                  color: 'white',
                  fontSize: 'initial',
                  backgroundColor: !item.point.isSuspended ? '#23CE7B' : 'grey.400',
                }}
              />
            ))}
            {user.pointsByTarget.length > 3 && (
              <Chip
                label={`Еще ${user.pointsByTarget.length - 3}`}
                size='small'
                sx={{ color: 'white', fontSize: 'initial', bgcolor: 'grey.300' }}
                clickable={true}
                onClick={toggleEditModal}
              />
            )}
          </Stack>
        </Stack>
        <ListItemSecondaryAction>
          <IconButton onClick={handleClickMenu} size='large'>
            <MenuIcon color='disabled' />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <MenuItem onClick={toggleEditModal}>Редактировать</MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      {editModalVisible && <EditUserPointsModal user={user} onCancel={toggleEditModal} />}
    </>
  )
}
