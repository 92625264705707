import React from 'react'
import { Box, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import { IPoint } from '../../../../graphql/types/points'
import { useStores } from '../../../../stores/hooks'

interface IProps {
  point: IPoint
}

export const PointStatus: React.FC<IProps> = observer(({ point }) => {
  const classes = useStyles()
  const { targetsStore } = useStores()
  const pointStatus = targetsStore.pointsStatuses.find((ps) => ps.deviceCode === point.deviceCode)
  const isOnline = pointStatus?.isOnline || false

  return (
    <Tooltip title={isOnline ? 'Online' : 'Offline'} placement='top'>
      <Box className={cn(classes.circle, isOnline ? classes.online : classes.offline)}></Box>
    </Tooltip>
  )
})

const useStyles = makeStyles((theme) => ({
  circle: {
    width: 12,
    height: 12,
    borderRadius: 10,
  },
  online: {
    backgroundColor: theme.palette.success.main,
  },
  offline: {
    backgroundColor: theme.palette.error.main,
  },
}))
