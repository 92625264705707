import React from 'react'
import { TextField, Box, Button, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikProps } from 'formik'
import { observer } from 'mobx-react-lite'

import { LoadableButton, PhoneField } from '../../../components/Form'
import { profileSchema } from './validation'
import { useStores } from '../../../stores/hooks'

export interface IProfileFormValues {
  name: string
  phone: string
  password: string
}

interface IProps {
  onSubmit: (values: IProfileFormValues) => void
  onCancel: () => void
  loading?: boolean
}

export const ProfileForm: React.FC<IProps> = observer(({ onSubmit, onCancel, loading }) => {
  const { authStore } = useStores()
  const classes = useStyles()
  const business = authStore.business

  const initialValues: IProfileFormValues = {
    name: business?.name || '',
    phone: business?.phone || '',
    password: '',
  }

  const handleFormSubmit = (values: IProfileFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={profileSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, setFieldValue, handleChange, handleSubmit }: FormikProps<IProfileFormValues>) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Paper className={classes.paper}>
            <TextField
              label='Имя'
              name='name'
              value={values.name}
              onChange={handleChange}
              error={!!errors.name}
              required
            />
            <PhoneField
              label='Телефон'
              name='phone'
              value={values.phone}
              onChange={(e) => setFieldValue('phone', `+${e.target.value.replace(/\D+/g, '')}`)}
              error={!!errors.phone}
              required
            />
            <Box mt={3}>
              <Typography>Смена пароля</Typography>
            </Box>
            <TextField
              label='Новый пароль'
              name='password'
              value={values.password}
              onChange={handleChange}
              error={!!errors.password}
              type='password'
            />
          </Paper>
          <Box mt={3} display='flex' justifyContent='flex-end'>
            <Box mr={2}>
              <Button onClick={onCancel}>Назад</Button>
            </Box>
            <LoadableButton loading={loading} color='primary' variant='contained' type='submit'>
              Сохранить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  );
})

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
}))
