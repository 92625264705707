import React, { useState } from 'react'
import { Box, FormControlLabel, Paper, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import { useMutation } from '@apollo/client'

import { ISwitchTargetRegistrationData, ISwitchTargetRegistrationVariables, ITarget } from '../../graphql/types/targets'
import { SWITCH_TARGET_REGISTRATION } from '../../graphql/queries/targets'

interface IProps {
  target: ITarget
}

export const RegistrationSwitchBox: React.FC<IProps> = ({ target }) => {
  const classes = useStyles()
  const [registerEnabled, setRegisterEnabled] = useState<boolean>(target.isRegistrationEnabled)
  const [switchRegistration] = useMutation<ISwitchTargetRegistrationData, ISwitchTargetRegistrationVariables>(
    SWITCH_TARGET_REGISTRATION
  )

  const toggleRegistration = async () => {
    setRegisterEnabled((prev) => !prev)

    await switchRegistration({
      variables: { id: target.id },
    })
  }

  return (
    <Paper className={classes.paper}>
      <Box className={classes.codeBox}>
        <Typography variant='h5'>{target.registrationCode}</Typography>
        <Typography variant='caption' color='textSecondary'>
          Код регистрации к объекту
        </Typography>
      </Box>
      <Alert
        severity={registerEnabled ? 'success' : 'error'}
        // variant='outlined'
        action={
          <FormControlLabel
            label={''}
            control={<Switch checked={registerEnabled} onChange={toggleRegistration} name='registerEnabled' />}
          />
        }
      >
        <Typography>{registerEnabled ? 'Регистрация включена' : 'Регистрация отключена'}</Typography>
      </Alert>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  codeBox: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    border: `1px dashed ${theme.palette.grey[300]}`,
    borderRadius: 8,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
  },
}))
