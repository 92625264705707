import React from 'react'
import { Box, IconButton } from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'

function TableActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props
  function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onPageChange(event, 0)
  }

  function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onPageChange(event, page - 1)
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onPageChange(event, page + 1)
  }

  function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box display='flex'>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='First Page' size='large'>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0 || count < rowsPerPage}
        aria-label='Previous Page'
        size='large'
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page === Math.ceil(count / rowsPerPage) - 1 || count < rowsPerPage}
        aria-label='Next Page'
        size='large'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page === Math.ceil(count / rowsPerPage) - 1 || count < rowsPerPage}
        aria-label='Last Page'
        size='large'
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  )
}

export default TableActions
