import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMutation } from '@apollo/client'

import { IPointFormValues, PointForm } from './PointForm'
import { useStores } from '../../../../stores/hooks'
import { TARGET_POINTS_QUERY, UPDATE_POINT_MUTATION } from '../../../../graphql/queries/points'
import { IPoint, IPointInput, IUpdatePointData, IUpdatePointVariables } from '../../../../graphql/types/points'
import { useSnackbar } from 'notistack'
import { usePointsFilter } from '../../../../graphql/hooks/targets'
import { usePagination } from '../../../../hooks/usePagination'

interface IProps {
  point: IPoint
  onCancel: () => void
}

export const EditPointModal: React.FC<IProps> = observer(({ point, onCancel }) => {
  const { targetsStore } = useStores()
  const filter = usePointsFilter()
  const { offset, limit } = usePagination()

  const { enqueueSnackbar } = useSnackbar()
  const targetId = targetsStore.selectedTarget!.id

  const [udpatePoint, { loading }] = useMutation<IUpdatePointData, IUpdatePointVariables>(UPDATE_POINT_MUTATION)

  const handleSubmit = async (values: IPointFormValues) => {
    const input: IPointInput = values

    await udpatePoint({
      variables: {
        id: point.id,
        input,
      },
      refetchQueries: [{ query: TARGET_POINTS_QUERY, variables: { targetId, filter, pagination: { offset, limit } } }],
    })

    enqueueSnackbar('Данные успешно сохранены', {
      variant: 'success',
    })

    onCancel()
  }

  return (
    <Dialog open maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Редактирование {point.title}</DialogTitle>
      <DialogContent>
        <PointForm point={point} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
      </DialogContent>
    </Dialog>
  )
})
