import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Link, useParams } from 'react-router-dom'
import { Cancel as FailIcon } from '@mui/icons-material'
import { useMutation } from '@apollo/client'

import { Loader } from '../../components/UI'
import { SendResetEmail, NewPasswordForm } from './components'
import { VERIFY_RESET_PASSWORD_TOKEN } from '../../graphql/queries/business'
import { IVerifyResetPasswordTokenData, IVerifyResetPasswordTokenVariables } from '../../graphql/types/business'

const ResetPassword: React.FC = () => {
  const params = useParams()
  const resetToken = params.token
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(true)
  const [hasValidToken, setHasValidToken] = useState<boolean>(false)
  const [verifyToken] = useMutation<IVerifyResetPasswordTokenData, IVerifyResetPasswordTokenVariables>(
    VERIFY_RESET_PASSWORD_TOKEN
  )

  useEffect(() => {
    if (!resetToken) {
      setLoading(false)
      return
    }

    ;(async () => {
      const { data } = await verifyToken({
        variables: { token: resetToken },
      })

      setHasValidToken(data?.verifyBusinessResetPasswordToken || false)
      setLoading(false)
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography variant='h5'>Восстановление пароля</Typography>
        {loading ? (
          <Loader />
        ) : (
          <Box>
            {resetToken && !hasValidToken && (
              <Box p={2} color='error.main' textAlign='center'>
                <FailIcon color='inherit' fontSize='large' />
                <Typography>Неверный токен</Typography>
              </Box>
            )}
            {hasValidToken && resetToken && <NewPasswordForm resetToken={resetToken} />}
            {!hasValidToken && !resetToken && <SendResetEmail />}
          </Box>
        )}
      </Paper>
      <Box mt={3} textAlign='center'>
        <Typography>
          Вспомнили пароль? <Link to='/login'>Войти</Link>
        </Typography>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}))

export default ResetPassword
