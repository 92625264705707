import gql from 'graphql-tag'

import { USER_FRAGMENT } from './users'

export const USER_INQUIRY_FRAGMENT = gql`
  fragment UserInquiryFragment on UserInquiry {
    userId
    targetId
    user {
      ...UserFragment
    }
    comment
    status
    statusChangedAt
    createdAt
  }
  ${USER_FRAGMENT}
`

export const TARGET_INQUIRIES_QUERY = gql`
  query TargetInquiriesQuery($targetId: Int!) {
    getUserInquiriesByTarget(targetId: $targetId) {
      ...UserInquiryFragment
    }
  }
  ${USER_INQUIRY_FRAGMENT}
`

export const CONFIRM_USER_INQUIRY = gql`
  mutation ConfirmUserInquiryMutation($input: ConfirmUserInquiryInput!) {
    confirmUserInquiry(input: $input)
  }
`
