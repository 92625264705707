import React from 'react'
import { MenuItem, TextField } from '@mui/material'
import { useQuery } from '@apollo/client'

import { IGetCitiesData } from '../../graphql/types/locations'
import { CITIES_QUERY } from '../../graphql/queries/locations'

interface IProps {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const CitySelectField: React.FC<IProps> = ({ value, onChange }) => {
  const { data } = useQuery<IGetCitiesData, {}>(CITIES_QUERY)
  const cities = data?.getCities.cities || []

  return (
    <TextField label='Город' variant='outlined' value={value} onChange={onChange} fullWidth margin='normal' select>
      {cities.map((city) => (
        <MenuItem key={`city-${city.id}`} value={city.id}>
          {city.title}
        </MenuItem>
      ))}
    </TextField>
  )
}
